export enum ActionType {
    READ_DATA = '[Message Context] READ DATA',
    GET_DATA = '[Message Context] GET DATA',
    SET_CUSTOMER = '[Message Context] SET CUSTOMER',
    SET_LOCATION = '[Message Context] SET LOCATION',
    SET_TARGET = '[Message Context] SET TARGET',
    SET_COMMAND = '[Message Context] SET COMMAND',
    SET_TYPE = '[Message Context] SET TYPE',
    SET_COMMAND_FILENAME = '[Message Context] SET COMMAND FILENAME',
    SET_PAYLOAD = '[Message Context] SET PAYLOAD',
    SET_PAYLOAD_FILENAME = '[Message Context] SET PAYLOAD FILENAME',
    SET_COMPRESS = '[Message Context] SET COMPRESS',
    SET_DISABLED = '[Message Context] SET DISABLED',
    SET_LOCATION_CODE = '[Message Context] SET LOCATION CODE',
    SET_ISINCUS = '[Message Context] SET ISINCUS',
    READ_COMMAND_PRESET_LIST = '[Message Context] READ COMMAND PRESET LIST',
    RESET_DATA = '[Message Context] RESET DATA',
    SET_SUBMIT_RESULT = '[Message Context] SET SUBMIT RESULT',
    SUBMIT_DATA = '[Message Context] SUBMIT DATA',
    SET_SUBMIT_PROCESSING = '[Message Context] SET SUBMIT PROCESSING',
}
