import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import SORT_ORDER from '../../../values/sort-order-enum'
import AppState from '../../types/app-state'
import {Message} from './state'

export const isLoadingSelector = (state: AppState): boolean =>
    state.messages.loading === LoadingState.NotPopulated ||
    state.messages.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState => state.messages?.loading

export const messagesSelector = (state: AppState): Message[] => state.messages?.messages

export const filteredMessagesSelector = (state: AppState): Message[] =>
    state.messages.messages
        .slice(0)
        .reverse()
        .sort((a, b) =>
            state.messages?.receiverFilter.sortOrder == SORT_ORDER.ASCENDING
                ? a.timestamp?.localeCompare(b.timestamp)
                : b.timestamp?.localeCompare(a.timestamp),
        )
        .filter((message) =>
            state.messages?.receiverFilter.selectedCustomer !== ''
                ? message.customer === state.messages.receiverFilter.selectedCustomer
                : state.messages.messages,
        )
        .filter((message) =>
            state.messages?.receiverFilter.selectedTrackingId !== ''
                ? message.trackingid?.startsWith(
                      state.messages?.receiverFilter.selectedTrackingId.toLowerCase(),
                  )
                : message.trackingid,
        )
// .filter((message) =>
//     state.messages?.receiverFilter.selectedStatus !== ''
//         ? message.status === state.messages?.receiverFilter.selectedStatus
//         : message.status,
// )

export const customerFilteredMessagesSelector = (state: AppState): Message[] =>
    state.messages.messages.filter((message) =>
        state.messages?.receiverFilter.selectedCustomer !== ''
            ? message.customer === state.messages.receiverFilter.selectedCustomer
            : state.messages.messages,
    )

export const selectedStatusSelector = (state: AppState): string =>
    state.messages?.receiverFilter.selectedStatus

export const selectedTrackingIdSelector = (state: AppState): string =>
    state.messages?.receiverFilter.selectedTrackingId

export const lastUpdatedSelector = (state: AppState): Moment | null | undefined =>
    state.messages.lastUpdate
