import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const RightContainerStyle = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${spacing(3)};
    justify-content: flex-start;
`

export const ButtonStyle = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 100%;
    text-decoration: none;
    height: 20px;
    width: 20px;

    &:hover {
        background-color: ${(props) => props.theme.navigationResponsive.dataArea.miniBackground};
    }
`
