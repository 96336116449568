import {TrackingId} from './state'
import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'

export const DATABASE_URL = '/api/v1/trackingid'

export const requestTrackingIds = (): Actions.RequestTrackingIdsAction => ({
    type: ActionType.REQUEST_TRACKING_IDS,
})

const setTrackingIds = (trackingIds: TrackingId[]): Actions.ReceiveTrackingIdsAction => ({
    type: ActionType.RECEIVE_TRACKING_IDS,
    payload: trackingIds,
})

export const fetchTrackingIds = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestTrackingIds())
        REST.get(DATABASE_URL)
            .then((response) => {
                dispatch(setTrackingIds(response.data.data.data))
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}

export const deleteTrackingId = (id: string): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.delete(`${DATABASE_URL}/${id}`)
            .then(() => {
                dispatch(fetchTrackingIds())
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}
