import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const Input = styled.input`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    ${smallFont()}
    height: 30px;
    width: 200px;
    margin: 3px;
    padding: ${spacing(1)};
`
