import {ActionsFooter} from '../../actions/actions-footer'
import {AdminSignInButton} from '../../actions/admin-sign-in-button'
import * as Styled from './row-based-layout.styled'
import {RowBasedLoginLayout} from './row-based-login-layout'

export function LoginPanel(): JSX.Element {
    return (
        <RowBasedLoginLayout>
            <Styled.MainActionsGrid useAvailableSpace={true}>
                <AdminSignInButton />
            </Styled.MainActionsGrid>
            <ActionsFooter />
        </RowBasedLoginLayout>
    )
}
