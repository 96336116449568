import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {trackingIdsSelector} from '../../../../../../store/state/tracking-ids/selectors'
import {NoDataCard} from '../../../receiver/components/response-list/no-data-card/no-data-card'
import {TrackingListTablePopulated} from './tracking-list-table-populated'

export function TrackingListLoaded(): JSX.Element {
    const trackingids = useTypedSelector(trackingIdsSelector)
    return trackingids?.length === 0 ? (
        <NoDataCard text="no data" />
    ) : (
        <TrackingListTablePopulated trackingids={trackingids} />
    )
}
