import {QmMessage} from './state'
import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'

export const DATABASE_URL = '/api/v1/qmmessage'

export const requestQmMessages = (): Actions.RequestQmMessagesAction => ({
    type: ActionType.REQUEST_QM_MESSAGES,
})

const setQmMessages = (qmMessages: QmMessage[]): Actions.ReceiveQmMessagesAction => ({
    type: ActionType.RECEIVE_QM_MESSAGES,
    payload: qmMessages,
})

export const fetchQmMessages = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestQmMessages())
        REST.get(DATABASE_URL)
            .then((response) => {
                dispatch(setQmMessages(response.data.data.data))
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}

export const deleteQmMessage = (id: string): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.delete(`${DATABASE_URL}/${id}`)
            .then(() => {
                dispatch(fetchQmMessages())
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}
