import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const HeaderWrapper = styled.div``

export const FirstRow = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${spacing(3)};
`

export const SecondRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${spacing(3)};
    /* padding: ${spacing(4)} ${spacing(7)}; */
    /* background-color: ${(props) => props.theme.colors.background.default}; */
`
