import {Node} from '../../../../../../store/state/nodes/state'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {Select} from './asset-select-input.styled'

interface SelectInputProps {
    label: string
    setChange: (value: string) => void
    selectOption: Node[]
    disabled: boolean
}

export function AssetSelectInput({
    label,
    setChange,
    selectOption,
    disabled,
}: SelectInputProps): JSX.Element {
    return (
        <DataCellFixed label={label}>
            <Select
                onChange={(e) => {
                    setChange(e.target.value)
                }}
                disabled={disabled}
            >
                <option key="default-option" value="">
                    Select {label.toLowerCase()}
                </option>

                {selectOption.map(({node, hostName}) => (
                    <option key={node} value={hostName}>
                        {hostName}
                    </option>
                ))}
            </Select>
        </DataCellFixed>
    )
}
