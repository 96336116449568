import {ReactNode, ReactNodeArray, useReducer} from 'react'
import {submitterReducer} from './state/reducer'
import {SubmitterContext} from './submitter-context'
import {DEFAULT_SUBMITTER_STATE} from './types/default-submitter-state'
import {SubmitterState} from './types/submitter-state'

type SubmitterProviderProps = {
    children: ReactNode | ReactNodeArray
}

export function SubmitterProvider({children}: SubmitterProviderProps): JSX.Element {
    const initialState: SubmitterState = DEFAULT_SUBMITTER_STATE

    const [state, dispatch] = useReducer(submitterReducer, initialState)

    return (
        <SubmitterContext.Provider value={{state, dispatch}}>{children}</SubmitterContext.Provider>
    )
}
