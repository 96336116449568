import {useEffect, useState} from 'react'
import {batch, useDispatch} from 'react-redux'
import {api} from '../../store/configure-store'
import useTypedSelector from '../../hooks/use-typed-selector'
import {loggedIn, loggingOut} from '../../store/state/session-data/action-creators'
import {allDataIsLoadedReselector} from './reselect/all-data-is-loaded-reselector'
import {fetchInitialData} from '../../store/state/auth/action-creators'
import * as Styled from './system-reinitialising.styled'
//import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LoadingSpinner} from '../../components/loading/loading'
import {fetchConfig} from '../../store/state/config/action-creators'

enum Status {
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
}

export function SystemReinitialising(): JSX.Element {
    const dispatch = useDispatch()
    const allDataIsLoaded = useTypedSelector(allDataIsLoadedReselector)
    const [status, setStatus] = useState(Status.UNVERIFIED)

    useEffect(() => {
        switch (status) {
            case Status.UNVERIFIED:
                try {
                    api.getAuth(
                        '/api/v1/auth/verify',
                        () => {
                            dispatch(fetchConfig())
                            setStatus(Status.VERIFIED)
                        },
                        () => {
                            dispatch(loggingOut())
                        },
                    )
                } catch {
                    dispatch(loggingOut())
                }
                break
            case Status.VERIFIED:
                dispatch(fetchInitialData())
        }
    }, [status, dispatch])

    useEffect(() => {
        if (allDataIsLoaded) {
            batch(() => {
                dispatch(loggedIn())
            })
        }
    }, [allDataIsLoaded, dispatch])
    return (
        <Styled.Screen>
            <Styled.NavigationBar>
                <Styled.NavigationContent>
                    {/*<Icon glyph="Medulla" width={214} height={46} className="navigation__logo" />*/}
                </Styled.NavigationContent>
            </Styled.NavigationBar>
            <Styled.Loading>
                <LoadingSpinner size={150} />
            </Styled.Loading>
        </Styled.Screen>
    )
}
