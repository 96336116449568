import ActionType from './action-type'
import * as Actions from './actions'

import {LoggedOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {DEFAULT_AUTH_STATE, AuthReduxState} from './state'

function receiveAuthToken(currentState: AuthReduxState, newState: string): AuthReduxState {
    if (currentState.authToken === newState) {
        return currentState
    }
    return {
        ...currentState,
        authToken: newState,
    }
}
function clearAuthToken(currentState: AuthReduxState): AuthReduxState {
    if (currentState.authToken === undefined) {
        return currentState
    }
    return {
        ...currentState,
        authToken: undefined,
    }
}
function logout(currentState: AuthReduxState): AuthReduxState {
    if (currentState.authToken === undefined) {
        return currentState
    }

    return DEFAULT_AUTH_STATE
}
export default function authReducer(
    state: AuthReduxState = DEFAULT_AUTH_STATE,
    action: Actions.Action | LoggedOutAction,
): AuthReduxState {
    switch (action.type) {
        case SessionActionType.LOGGED_OUT:
            return logout(state)

        case ActionType.RECEIVE_AUTH_TOKEN:
            return receiveAuthToken(state, action.payload)

        case ActionType.CLEAR_AUTH_TOKEN:
            return clearAuthToken(state)
        /* istanbul ignore next */
        default:
            return state
    }
}
