import {animated} from 'react-spring'
import styled from 'styled-components'

interface LayerProps {
    z: number
}
export const Layer = styled(animated.span)<LayerProps>`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: ${(props) => props.theme.modalDialog.overlay};
    z-index: ${(props) => props.z};
    backdrop-filter: blur(8px);
    opacity: 1;
`
