// import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {OwlLogo} from '../../images/owl-logo'
import * as Styles from './_styles/logo.styled'

export function Logo(): JSX.Element {
    return (
        <Styles.Logo>
            <OwlLogo scale={0.6} />
            {/* <Icon glyph="Medulla" /> */}
        </Styles.Logo>
    )
}
