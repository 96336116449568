import {GuidType} from '../../../../../../values/generic-type-defintions'
import SORT_ORDER from '../../../../../../values/sort-order-enum'
import {TrackingListState} from './tracking-list-state'

export const DEFAULT_TRACKING_LIST_STATE: TrackingListState = {
    detailExpanded: new Map<GuidType, boolean>(),
    listExpanded: false,
    totalNumberOfTrackingIds: undefined,
    totalNumberOfPages: undefined,
    pageSize: 10,
    selectedPage: 0,
    sortOrder: SORT_ORDER.ASCENDING,
}
