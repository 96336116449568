import * as Styles from './checkbox.styled'

interface Props {
    checked: boolean
    onChange: (value: boolean) => void
    label: string
}
export function Checkbox({checked, onChange, label}: Props): JSX.Element {
    return (
        <Styles.Label>
            <Styles.Text>{label}</Styles.Text>
            <Styles.CheckboxInput
                type="checkbox"
                onChange={() => onChange(!checked)}
                checked={checked}
            />
            <Styles.StyledCheckbox />
        </Styles.Label>
    )
}
