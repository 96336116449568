import ActionType from './action-type'
import * as Actions from './actions'
import AppState from '../../types/app-state'
import {Role} from '../../../values/Role'
import {ThunkAction} from 'redux-thunk'
import {Api} from '../../../api/api'
import {REST} from '../../..'
import {User} from './state'

export const DATABASE_URL = '/api/v1/users'

export const getCurrentUserEmail = (userEmail: string): Actions.getCurrentUserAction => ({
    type: ActionType.GET_CURRENT_USER,
    payload: userEmail,
})

export const requestCurrentUser = (): Actions.requestCurrentUserAction => ({
    type: ActionType.REQUEST_CURRENT_USER,
})

const setCurrentUser = (user: User): Actions.receiveCurrentUserAction => ({
    type: ActionType.RECEIVE_CURRENT_USER,
    payload: user,
})

export const fetchCurrentUser = (
    email: string,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestCurrentUser())
        REST.get(`${DATABASE_URL}?email=${email}`).then((response) => {
            dispatch(setCurrentUser(response.data[0]))
        })
    }
}

export function setUserRole(roles: Role[]): Actions.setUserRoleAction {
    return {
        type: ActionType.SET_ROLES_FOR_CURRENT_USER,
        payload: roles,
    }
}
