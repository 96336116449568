import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface MessagesProps {
    width: number
}

export const Messages = styled.div<MessagesProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${(props) => (props.width > 1350 ? '750px' : '625px')};
    padding: 0 ${spacing(2)};
    gap: ${spacing(2)};
`
export const ScrollableList = styled.div<MessagesProps>`
    height: 500px;
    width: ${(props) => (props.width > 1350 ? '740px' : '615px')};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: ${spacing(1)};
`
