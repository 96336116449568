import {useTheme} from 'styled-components'
import StatusState from '../../../../../../../values/status-state-enum'
import * as Styled from './filter-by-status.styled'
import {FilterCard} from './filter-card'

export function FilterByStatus(): JSX.Element {
    const theme = useTheme()

    return (
        <Styled.ContentContainerMiddle backgroundColor={theme.colors.background.default}>
            <Styled.ContentArea>
                <FilterCard status={''} />
                <FilterCard status={StatusState.Received} />
                <FilterCard status={StatusState.Assigned} />
                <FilterCard status={StatusState.Success} />
                <FilterCard status={StatusState.Failed} />
            </Styled.ContentArea>
        </Styled.ContentContainerMiddle>
    )
}
