import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {GridLayout} from '../../../shared/grid-layout.enum'
import {
    showOneColNarrow,
    showOneColWide,
    showTwoColNarrow,
} from '../../../shared/grid-layout-helper'
interface InputPanelStyleProps {
    gridLayout: GridLayout
}

function getGridTemplate(gridLayout: GridLayout): string {
    if (showTwoColNarrow(gridLayout)) {
        return "'CUSTOMER CUSTOMER-VALUE TYPE TYPE-VALUE' 'LOCATION LOCATION-VALUE COMMAND COMMAND-VALUE' 'TARGET TARGET-VALUE PAYLOAD PAYLOAD-VALUE' 'ASSET ASSET-VALUE COMPRESSED COMPRESSED-VALUE'"
    } else if (showOneColWide(gridLayout)) {
        return "'CUSTOMER CUSTOMER-VALUE' 'LOCATION LOCATION-VALUE' 'TARGET TARGET-VALUE' 'ASSET ASSET-VALUE' 'TYPE TYPE-VALUE' 'COMMAND COMMAND-VALUE' 'PAYLOAD PAYLOAD-VALUE' 'COMPRESSED COMPRESSED-VALUE'"
    } else if (showOneColNarrow(gridLayout)) {
        return "'CUSTOMER' 'CUSTOMER-VALUE' 'LOCATION' 'LOCATION-VALUE' 'TARGET' 'TARGET-VALUE' 'ASSET-VALUE' 'TYPE' 'TYPE-VALUE' 'COMMAND' 'COMMAND-VALUE' 'PAYLOAD' 'PAYLOAD-VALUE' 'COMPRESSED-VALUE'"
    }
    return "'CUSTOMER CUSTOMER-VALUE EMPTY EMPTY TYPE TYPE-VALUE' 'LOCATION LOCATION-VALUE EMPTY EMPTY COMMAND COMMAND-VALUE' 'TARGET TARGET-VALUE EMPTY EMPTY PAYLOAD PAYLOAD-VALUE' 'ASSET ASSET-VALUE EMPTY EMPTY COMPRESSED COMPRESSED-VALUE'"
}

export const InputPanelStyle = styled.div<InputPanelStyleProps>`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: ${(props) => getGridTemplate(props.gridLayout)};
    grid-row-gap: ${spacing(1)};
    grid-column-gap: ${spacing(6)};
    padding: ${spacing(2)};
`
export const PanelLayout = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`
