import produce from 'immer'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {TrackingListState} from '../types/tracking-list-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'

export const trackingListReducer = produce((draft: TrackingListState, action: AllActions) => {
    switch (action.type) {
        case ActionType.TOGGLE_DETAIL_EXPANDED:
            if (!draft.detailExpanded) {
                draft.detailExpanded = new Map<GuidType, boolean>()
            }
            const existingState = draft.detailExpanded.get(action.payload) ?? false
            draft.detailExpanded.set(action.payload, existingState === false)
            break
        case ActionType.TOGGLE_LIST_EXPANDED:
            draft.listExpanded = action.payload
            break
        case ActionType.SELECT_PAGE:
            draft.selectedPage = action.payload
            break
        case ActionType.SET_TOTAL_NUMBER_OF_TRACKING_IDS:
            draft.totalNumberOfTrackingIds = action.payload
            break
        case ActionType.SORT_DATA:
            draft.sortOrder = action.payload
            break
    }
    return draft
})
