enum ActionType {
    REQUEST_MESSAGES = '[CORE/MESSAGES] REQUEST MESSAGES',
    RECEIVE_MESSAGES = '[CORE/MESSAGES] RECEIVE MESSAGES',
    DELETE_MESSAGE = '[CORE/MESSAGES] DELETE MESSAGE',
    SET_CUSTOMER = '[CORE/MESSAGES] SET CUSTOMER',
    SET_TRACKINGID = '[CORE/MESSAGES] SET TRACKINGID',
    SET_BY_STATUS = '[CORE/MESSAGES] SET BY STATUS',
    SORT_DATA = '[Message Context] SORT DATA',
}

export default ActionType
