import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './aside.styled'

export type LayoutType = 'COLUMN' | 'ROW'
interface AsideProps {
    layout?: LayoutType
}

function getTextSize(layout: string, height: number | undefined): Styled.TextSizeType {
    if (layout === 'ROW') {
        return height !== undefined && height >= 600 ? 'LARGE' : 'SMALL'
    }

    return height !== undefined && height >= 420 ? 'LARGE' : 'SMALL'
}

export function Aside({layout = 'COLUMN'}: AsideProps): JSX.Element {
    const {height} = useDimensions()

    return (
        <Styled.Aside textSize={getTextSize(layout, height)} centered={layout === 'ROW'}>
            &nbsp;
        </Styled.Aside>
    )
}
