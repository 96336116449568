import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const SubmitAreaStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: ${spacing(3)};
    padding: ${spacing(2)};
`

export const ButtonAreaStyle = styled.div`
    display: flex;
    gap: ${spacing(3)};
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
`
