import styled from 'styled-components'

export const CssGrid = styled.header`
    display: grid;
    grid-template-columns: 44px 150px;
    column-gap: 33px;
    row-gap: 10px;
`

export const LogoCell = styled.div`
    grid-column: 1;
    grid-row: 1;
`

export const NameCell = styled.div`
    grid-column: 2;
    grid-row: 1;
    align-self: center;
`

export const ByTextCell = styled.div`
    grid-column: 2;
    grid-row: 2;
`
