import {Aside} from '../../aside/aside'
import {Header, HeaderSize} from '../../header/header'
import * as Styled from './row-based-layout.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/session-data/selectors'
import {ReactNode, ReactNodeArray} from 'react'
import {LoginPageTemplate} from '../../../page-template/login-page-template'

export function isRowBasedLoginFormat(width: number | undefined): boolean {
    return width === undefined || width < 1100
}

interface RowBasedLoginLayoutProps {
    children: ReactNode | ReactNodeArray
}
export function RowBasedLoginLayout({children}: RowBasedLoginLayoutProps): JSX.Element {
    const {height} = useDimensions()
    const error = useTypedSelector(errorSelector)
    const hasError = error !== undefined && error?.length > 0

    return (
        <LoginPageTemplate>
            <Styled.Content>
                <Header headerSize={HeaderSize.SMALL} />
                <Styled.CallToActionSection showError={hasError}>
                    {children}
                </Styled.CallToActionSection>
                <Styled.AsideWrapper height={height}>
                    <Aside layout="ROW" />
                </Styled.AsideWrapper>
            </Styled.Content>
        </LoginPageTemplate>
    )
}
