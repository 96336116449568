import {Overlay} from '../shared/overlay'
import {ShowResult} from './components/show-result-form'

export function LoadingModal(): JSX.Element {
    return (
        <Overlay
            onClickFn={(e) => {
                e && e.preventDefault()
            }}
            z={900}
        >
            <ShowResult />
        </Overlay>
    )
}
