export type Role = 'APP' | 'DEVELOPER' | 'THREAT_ANALYST' | 'SEC_OPS_ADMIN' | 'USER'

export const isUserAllowed = (userRoles: Role[] | undefined, rolesAllowed: Role[]): boolean => {
    if (!userRoles || !rolesAllowed) {
        return false
    }

    return rolesAllowed.some((allowedRole: Role) => {
        return userRoles.some((userRole: Role) => userRole === allowedRole)
    })
}
