import {createSelector} from 'reselect'
import {fetchingStatusSelector} from '../../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../../store/state/config/state'
import {isLoadingSelector} from '../../../store/state/messages/selectors'

export const allDataIsLoadedReselector = createSelector(
    fetchingStatusSelector,
    isLoadingSelector,
    (configFetchingStatus, isLoadingSelector): boolean => {
        return configFetchingStatus !== ConfigFetchingStatus.INITIALISED && isLoadingSelector
            ? false
            : true
    },
)
