import styled from 'styled-components'
import {smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

interface TableHeaderCellProps {
    gridColumn: number
    selectable?: boolean
    active?: boolean
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    display: flex;
    flex-direction: column;
    gap: ${spacing(1)};
    justify-content: center;
    color: ${(props) =>
        props.active ? props.theme.colors.text.warning : props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`
interface TableHeaderContentProps {
    active?: boolean
}
export const TableHeaderContent = styled.div<TableHeaderContentProps>`
    display: flex;
    column-gap: ${spacing(1)};
    align-items: center;
    position: relative;
`

export const InputFieldWrapper = styled.div`
    display: flex;
    position: relative;
`
interface IconStyledProps {
    arrowDec?: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transition: transform 0.2s, color 0.2s;
    transition-timing-function: ease-in-out;
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
    color: ${(props) => props.theme.colors.link.primary};
`
