import styled from 'styled-components'

interface TitleProps {
    smallSize: boolean
}

export const Title = styled.h1<TitleProps>`
    font-size: ${(props) => (props.smallSize ? 36 : 56)}px;
    line-height: ${(props) => (props.smallSize ? 54 : 84)}px;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 0;
    margin: 0;
`
