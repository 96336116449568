import ActionType from './action-type'
import * as Actions from './actions'
import {ErrorOccurred} from '../session-data/action-creators'
import {AnyAction} from 'redux'
import {ThunkAction} from 'redux-thunk'

import {Api} from '../../../api/api'
import AppState from '../../types/app-state'
import {fetchMessages} from '../messages/action-creators'
import {fetchLocations} from '../locations/action-creators'
import {fetchEnvironments} from '../environments/action-creators'
import {fetchTrackingIds} from '../tracking-ids/action-creators'
import {fetchQmMessages} from '../qm-messages/action-creators'
import {fetchNodes} from '../nodes/action-creators'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logout(): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any): void => {
        dispatch(clearAuthToken())
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorOccured(error: string): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any): void => {
        dispatch(ErrorOccurred(error))
    }
}

export function authTokenRetrieved(authToken: string): Actions.AuthTokenRetrievedAction {
    return {
        type: ActionType.RECEIVE_AUTH_TOKEN,
        payload: authToken,
    }
}

export function clearAuthToken(): Actions.ClearAuthTokenAction {
    return {
        type: ActionType.CLEAR_AUTH_TOKEN,
    }
}

export function fetchInitialData(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch(fetchMessages())
        dispatch(fetchEnvironments())
        dispatch(fetchLocations())
        dispatch(fetchTrackingIds())
        dispatch(fetchQmMessages())
        dispatch(fetchNodes())
    }
}
export function fetchExtendedData(): ThunkAction<void, AppState, Api, AnyAction> {
    return () => {
        // eslint-disable-next-line no-console
        console.log('to be filled in as API becomes available')
    }
}
