export type OAuthClientIdsType = {
    google: string | undefined
}

export enum ConfigFetchingStatus {
    UNINITIALISED = 'UNINITIALISED',
    REQUESTING = 'REQUESTING',
    INITIALISED = 'INITIALISED',
    NEEDS_RESETTING = 'NEEDS_RESETTING',
    REREQUESTING = 'REREQUESTING',
}
export interface ConfigReduxState {
    auth: string
    fetchingStatus: ConfigFetchingStatus
    oauthClientIds: OAuthClientIdsType
}

export const DEFAULT_OAUTH_CLIENT_IDS: OAuthClientIdsType = {
    google: undefined,
}

export const DEFAULT_CONFIG_STATE: ConfigReduxState = {
    auth: '',
    fetchingStatus: ConfigFetchingStatus.UNINITIALISED,
    oauthClientIds: DEFAULT_OAUTH_CLIENT_IDS,
}
