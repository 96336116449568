import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const NavigationLinkStyle = styled(NavLink)`
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
    text-decoration: none;
    width: 100%;
    padding: ${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(3)};
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
    transition: all 1s ease;
    border-right: ${spacing(1)} solid transparent;
    :hover {
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        text-decoration: inherit;
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
    &.${(props) => props.activeClassName} {
        border-right: ${spacing(1)} solid
            ${(props) => props.theme.navigationResponsive.active.underLine};
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
`
export const IndicatorDot = styled.div`
    display: inline-block;
    width: 15px;
    margin-left: ${spacing(1)};
    height: 15px;
    background-color: ${(props) => props.theme.navigationResponsive.active.background};
    border-radius: 50%;
`
export const DashboardLinkStyle = styled.div`
    overflow-y: auto;
    max-height: calc(100vh - 49px);
    max-width: max(100vw, 320px);
`
