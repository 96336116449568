import * as Styles from './_styles/nav-bar-link.styled'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'

interface NavBarLinkProps {
    id: string
    path: string
    navLinkText: string
}

export function NavBarLink({id, path, navLinkText}: NavBarLinkProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styles.NavigationLink id={id} to={path} activeClassName="activeLink" width={width}>
            {navLinkText}
        </Styles.NavigationLink>
    )
}
