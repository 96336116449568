import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'

export interface SubmittedCommand {
    env: string
    customer: string
    location: string
    target: string
    command: string
    filetype: string
    payload: string
    compress: boolean
    commandfilename: string
    timestamp: string
}
export interface TrackingId {
    _id: string
    submittedargs: SubmittedCommand
    trackingid: string
}

export interface TrackingIdsReduxState {
    loading: LoadingState
    trackingIds: TrackingId[]
    lastUpdate: Moment | null
}

export const DEFAULT_TRACKING_IDS_STATE: TrackingIdsReduxState = {
    loading: LoadingState.NotPopulated,
    trackingIds: [],
    lastUpdate: null,
}
