import {GuidType} from '../../../../../../values/generic-type-defintions'
import {FormattedCommand, SubmitResult} from '../types/submitter-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function setCustomer(value: string): Actions.SetCustomerAction {
    return {type: ActionType.SET_CUSTOMER, payload: value}
}

export function setLocation(value: string): Actions.SetLocationAction {
    return {type: ActionType.SET_LOCATION, payload: value}
}

export function setTarget(value: string): Actions.SetTargetAction {
    return {type: ActionType.SET_TARGET, payload: value}
}

export function setCommand(value: string): Actions.SetCommandAction {
    return {type: ActionType.SET_COMMAND, payload: value}
}

export function setType(value: string): Actions.SetTypeAction {
    return {type: ActionType.SET_TYPE, payload: value}
}

export function readCommandPresetList(value: string[]): Actions.ReadCommandPresetListAction {
    return {type: ActionType.READ_COMMAND_PRESET_LIST, payload: value}
}

export function setCommandFilename(value: string): Actions.SetCommandFilenameAction {
    return {type: ActionType.SET_COMMAND_FILENAME, payload: value}
}

export function setPayload(value: string): Actions.SetPayloadAction {
    return {type: ActionType.SET_PAYLOAD, payload: value}
}

export function setPayloadFilename(value: string): Actions.SetPayloadFilenameAction {
    return {type: ActionType.SET_PAYLOAD_FILENAME, payload: value}
}

export function setCompress(value: boolean): Actions.SetCompressAction {
    return {type: ActionType.SET_COMPRESS, payload: value}
}

export function setLocationCode(value: GuidType): Actions.SetLocationCodeAction {
    return {type: ActionType.SET_LOCATION_CODE, payload: value}
}

export function setDisabled(value: boolean): Actions.SetDisabledAction {
    return {type: ActionType.SET_DISABLED, payload: value}
}

export function setIsIncus(value: boolean): Actions.SetIsIncusAction {
    return {type: ActionType.SET_ISINCUS, payload: value}
}

export function resetChanges(): Actions.ResetDataAction {
    return {type: ActionType.RESET_DATA}
}

export function submitMessage(command: FormattedCommand): Actions.SubmitDataAction {
    const submitCommand = `${command.customer}`
    return {type: ActionType.SUBMIT_DATA, payload: submitCommand}
}

export function setSubmitResult(value: SubmitResult): Actions.SetSubmitResultAction {
    return {type: ActionType.SET_SUBMIT_RESULT, payload: value}
}

export function setSubmitProcessing(value: boolean): Actions.SetSubmitProcessingAction {
    return {type: ActionType.SET_SUBMIT_PROCESSING, payload: value}
}
