import {combineReducers} from 'redux'
import staticReducers from './static-reducers'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = () =>
    combineReducers({
        ...staticReducers,
    })

export default rootReducer
