import {ConfigFetchingStatus, ConfigReduxState, OAuthClientIdsType} from './state'
import AppState from '../../types/app-state'

export const configSelector = (state: AppState): ConfigReduxState => state.config

export const fetchingStatusSelector = (state: AppState): ConfigFetchingStatus =>
    state.config.fetchingStatus

export const oauthClientIdsSelector = (state: AppState): OAuthClientIdsType =>
    state.config.oauthClientIds
