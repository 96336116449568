import styled from 'styled-components'

export const Select = styled.select`
    background-color: white;
    font-size: 12px;
    line-height: 17px;
    height: 30px;
    width: 200px;
    margin: 3px;
    border-radius: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
