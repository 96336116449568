import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {messagesSelector} from '../../../../../../store/state/messages/selectors'
import {usePagedReceiver} from '../../contexts/use-paged-receiver'
import {DataCellFixed} from './data-cell-fixed-expanded'
import * as Styled from './message-details-expanded.styled'

export function MessageDetailsExpanded(): JSX.Element {
    const messages = useTypedSelector(messagesSelector)
    const {displayDetails} = usePagedReceiver()
    const displayData = messages.find((el) => el._id === displayDetails?._id)

    return (
        <Styled.DetailsLayout>
            <Styled.Title>Response Details</Styled.Title>
            <Styled.Details>
                <DataCellFixed label="Customer">{displayData?.customer}</DataCellFixed>
                <DataCellFixed label="Tracking ID">{displayData?.trackingid}</DataCellFixed>
                <DataCellFixed label="Location">{displayData?.location}</DataCellFixed>
                <DataCellFixed label="Target">{displayData?.target}</DataCellFixed>
                <DataCellFixed label="StdOut">{displayData?.stdout}</DataCellFixed>
                <DataCellFixed label="StdErr">{displayData?.stderr}</DataCellFixed>
                <DataCellFixed label="Status">{displayData?.status}</DataCellFixed>
                <DataCellFixed label="Payload">{displayData?.payload}</DataCellFixed>
                <DataCellFixed label="Receipt Time">{displayData?.timestamp}</DataCellFixed>
            </Styled.Details>
        </Styled.DetailsLayout>
    )
}
