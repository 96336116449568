import LoadingState from '../../../values/loading-state-enum'
import {Role} from '../../../values/Role'

export interface User {
    name: string
    email: string
    role: Role[]
}

export interface UserReduxState {
    isFetching: LoadingState
    currentUserEmail: string
    user: User
}

export const DEFAULT_USER_STATE: UserReduxState = {
    isFetching: LoadingState.NotPopulated,
    currentUserEmail: '',
    user: {} as User,
}
