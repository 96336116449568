import * as Styled from './styles/actions-footer.styled'

export function ActionsFooter(): JSX.Element {
    const appVersion = `V ${process.env.REACT_APP_INCUS_COMMUNICATOR_TOOLS_VERSION}${
        process.env.REACT_APP_BUILD_NUMBER ? `+${process.env.REACT_APP_BUILD_NUMBER}` : ''
    }`

    return (
        <Styled.ActionsFooter>
            <span id="app-version">{appVersion}</span>
        </Styled.ActionsFooter>
    )
}
