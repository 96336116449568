import {Checkbox} from '../../../../../../components/checkbox/checkbox'
import {useSubmitter} from '../../contexts/use-submitter'

export function CheckboxSelect(): JSX.Element {
    const {compress, setCompress} = useSubmitter()

    return (
        <Checkbox
            checked={compress}
            onChange={() => setCompress(!compress)}
            label={'Compress payload'}
        />
    )
}
