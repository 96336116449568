import * as Styled from './_styled/tracking-list-table-row.styled'
import {TrackingId} from '../../../../../../store/state/tracking-ids/state'
import {TableCell} from './tracking-list-table-cell'
import {TableDetailCell} from './tracking-list-table-detail-cell'
import {TrackingIdDetailExpanded} from './tracking-id-detail-expanded'
import {useTrackingList} from '../../contexts/use-tracking-list'
import {formatDate} from '../../../shared/data-helper'

interface TableRowProps {
    trackingid: TrackingId
    index: number
}

export function TableRow({trackingid, index}: TableRowProps): JSX.Element {
    const gridRow = index * 2 + 1
    const {detailExpanded} = useTrackingList()
    const isExpanded = detailExpanded.get(trackingid._id) === true
    const formattedTimestamp = formatDate(trackingid.submittedargs.timestamp)

    return (
        <>
            <Styled.TableDataRow gridRow={gridRow} id={`data-sme-row_${trackingid._id}`} />
            <TableCell content={trackingid.trackingid} gridRow={gridRow} gridColumn={2} />
            <TableCell content={formattedTimestamp} gridRow={gridRow} gridColumn={3} />
            <TableDetailCell gridRow={gridRow} gridColumn={4} id={trackingid._id} />
            <TrackingIdDetailExpanded
                trackingid={trackingid}
                gridRow={gridRow + 1}
                detailExpanded={isExpanded}
            />
        </>
    )
}
