import {OwlLogo} from '../../images/owl-logo'
import * as Styled from './_styled/logo-container.styled'

export function LogoContainer(): JSX.Element {
    return (
        <Styled.LeftContainerStyle>
            <Styled.LogoStyle>
                <OwlLogo scale={0.6} />
            </Styled.LogoStyle>
        </Styled.LeftContainerStyle>
    )
}
