import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import StatusState from '../../../../../../values/status-state-enum'
import {getBorderLeft} from '../shared/data-helper'

interface MessageCardProps {
    status: StatusState | string
    isSelected?: boolean
}

export const MessageCard = styled.div<MessageCardProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${spacing(2)};
    max-width: 600px;
    ${smallFont()}
    padding: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    border-radius: 0 ${spacing(1)} ${spacing(1)} 0;
    border-left: thick solid ${(props) => getBorderLeft(props.status, props.theme)};
`

export const MessageArea = styled.div`
    cursor: pointer;
`

export const CardTitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing(2)};
`
export const CardTitleData = styled.h2`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    flex: 1;
`
interface CardDataRowProps {
    width: number
}

export const CardDataRow = styled.div<CardDataRowProps>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing(2)};
`
interface CardDataCellProps {
    titleOnSameLine?: boolean
}

export const CardDataCell = styled.div<CardDataCellProps>`
    flex: 1;
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    padding-right: ${spacing(1)};
`

export const ReducedText = styled.div`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.light};
    line-height: 16px;
    padding-right: ${spacing(2)};
`
