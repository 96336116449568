import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {BREAK_POINT_SINGLE_COLUMN} from '../../../../shared/header/header.styled'

export function getGridTemplateColumns(width: number): string {
    return width > BREAK_POINT_SINGLE_COLUMN
        ? `${spacing(1)} auto 220px 180px ${spacing(1)}`
        : `${spacing(1)} auto 220px 120px ${spacing(1)}`
}

interface TableDataGridProps {
    width: number
}

export const TableDataGrid = styled.div<TableDataGridProps>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    gap: ${spacing(1)};
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.colors.background.lowContrast};
`
