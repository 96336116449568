import {ReactNodeArray} from 'react'
import {ReactNode} from 'react'
import {NavigationBar} from '../../components/navigation/navigation-bar'
import {ScrollablePage} from './scrollable-page.styled'

interface Props {
    children: ReactNode | ReactNodeArray
}

export function ScrollablePageTemplate({children}: Props): JSX.Element {
    return (
        <ScrollablePage>
            <NavigationBar />
            {children}
        </ScrollablePage>
    )
}
