import * as Styled from './show-tracking-id.styled'
import {Copy} from 'react-feather'

interface ShowTrackigIdProps {
    receivedMessage: string
}

export function ShowTrackigId({receivedMessage}: ShowTrackigIdProps): JSX.Element {
    return (
        <Styled.Wrapper>
            <Styled.Header>Command submitted with tracking id :</Styled.Header>
            <Styled.TrackingIdArea>
                <Styled.TrackingIdValue>{receivedMessage}</Styled.TrackingIdValue>
                <Styled.CopyButton
                    id="tracking-id-copy-button"
                    onClick={() => {
                        navigator.clipboard.writeText(receivedMessage)
                    }}
                >
                    <Copy />
                </Styled.CopyButton>
            </Styled.TrackingIdArea>
        </Styled.Wrapper>
    )
}
