import produce from 'immer'
import {DEFAULT_SUBMITTER_STATE, DEFAULT_SUBMIT_RESULT} from '../types/default-submitter-state'
import {SubmitterState} from '../types/submitter-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'

export const submitterReducer = produce((draft: SubmitterState, action: AllActions) => {
    switch (action.type) {
        case ActionType.SET_CUSTOMER:
            draft.submitData.customer = action.payload
            break
        case ActionType.SET_LOCATION:
            draft.submitData.location = action.payload
            break
        case ActionType.SET_TARGET:
            draft.submitData.target = action.payload
            break
        case ActionType.SET_COMMAND:
            draft.submitData.command = action.payload
            break
        case ActionType.SET_TYPE:
            draft.submitData.filetype = action.payload
            break
        case ActionType.SET_COMMAND_FILENAME:
            draft.submitData.commandfilename = action.payload
            break
        case ActionType.SET_PAYLOAD:
            draft.submitData.payload = action.payload
            break
        case ActionType.SET_PAYLOAD_FILENAME:
            draft.submitData.payloadfilename = action.payload
            break
        case ActionType.SET_COMPRESS:
            draft.submitData.compress = action.payload
            break
        case ActionType.SET_LOCATION_CODE:
            draft.selectedLocationCode = action.payload
            break
        case ActionType.SET_DISABLED:
            draft.disabled = action.payload
            break
        case ActionType.READ_COMMAND_PRESET_LIST:
            draft.commandPresetList = action.payload
            break
        case ActionType.SET_ISINCUS:
            draft.isIncus = action.payload
            break
        case ActionType.SET_SUBMIT_RESULT:
            draft.submitResult = action.payload
            break
        case ActionType.SET_SUBMIT_PROCESSING:
            draft.isSubmitProcessing = action.payload
            draft.submitResult = DEFAULT_SUBMIT_RESULT
            break
        case ActionType.RESET_DATA:
            draft = DEFAULT_SUBMITTER_STATE
            break
    }
    return draft
})
