import {ActionType} from './action-type'
import * as Actions from './actions'

export function readMessage(trackingid: string): Actions.ReadDataAction {
    return {type: ActionType.READ_DATA, payload: trackingid}
}

export function selectPage(pageIndex: number | undefined): Actions.SelectPage {
    return {type: ActionType.SELECT_PAGE, payload: pageIndex}
}

export function resetPage(): Actions.ResetPage {
    return {type: ActionType.RESET_PAGE}
}

export function SetTotalNumberOfMessages(value: number): Actions.SetTotalNumberOfMessages {
    return {type: ActionType.SET_TOTAL_NUMBER_OF_MESSAGES, payload: value}
}

export function closeDetails(): Actions.CloseDetailsAction {
    return {type: ActionType.CLOSE_DETAILS}
}
