import {useDispatch} from 'react-redux'
import * as Styled from './message-handling.styled'
import {deleteMessage} from '../../../../../../store/state/messages/action-creators'
import {X} from 'react-feather'

interface MessageHandlingProps {
    id: string
}

export function MessageHandling({id}: MessageHandlingProps): JSX.Element {
    const dispatch = useDispatch()

    return (
        <Styled.DataActionArea>
            {/* <Styled.ButtonWrapper onClick={() => dispatch(closeMessage(id))}>
                <Check height={18} width={18} color="green" /> Close
            </Styled.ButtonWrapper>
            <Styled.ButtonWrapper onClick={() => dispatch(openMessage(id))}>
                <CornerUpLeft height={18} width={18} color="orange" /> Open
            </Styled.ButtonWrapper> */}
            <Styled.ButtonWrapper onClick={() => dispatch(deleteMessage(id))}>
                <X height={18} width={18} color="red" /> Delete
            </Styled.ButtonWrapper>
        </Styled.DataActionArea>
    )
}
