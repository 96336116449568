import AppState from '../../types/app-state'
import {LoginStatus} from './state'

function hasError(state: AppState): boolean {
    return state.sessionData.error !== undefined && state.sessionData.error.length > 0
}

export const isLoggedInSelector = (state: AppState): boolean =>
    state.sessionData.loginStatus === LoginStatus.LOGGED_IN && !hasError(state)

export const logInStatusSelector = (state: AppState): LoginStatus => state.sessionData.loginStatus

export const errorSelector = (state: AppState): string | undefined => state.sessionData.error

export const hasErrorSelector = (state: AppState): boolean => hasError(state)

export const userLogInFlowSelector = (state: AppState): boolean => state.sessionData.userLogInFlow
