import {CyberOwlTheme} from './theme'
import {Colors} from './colours'

export const DarkTheme: CyberOwlTheme = {
    colors: {
        text: {
            default: Colors.white,
            deemphasize: Colors.grey5,
            error: Colors.red2,
            warning: Colors.red3,
        },
        background: {
            selectedLocationPage: Colors.grey4,
            page: Colors.grey8,
            default: Colors.grey5,
            lowContrast: Colors.grey6,
        },
        boxShadow: {
            highContrast: '#00000034',
        },
        link: {
            primary: Colors.blue2,
        },
        border: {
            lowContrast: Colors.grey2,
            active: Colors.blue2,
            default: Colors.grey14,
        },
        newIndicator: {
            text: Colors.red5,
            flash: Colors.red6,
            background: Colors.red3,
        },
    },
    login: {
        errorBorder: Colors.red3,
        errorBackground: Colors.red3_21percent,
        errorContent: Colors.white,
    },
    loadingPage: {
        background: Colors.grey5,
    },
    navigationResponsive: {
        dataArea: {
            background: Colors.grey7,
            miniBackground: Colors.grey5,
            locationExpandedArea: Colors.grey4,
            textColor: Colors.grey1,
            miniTextColor: Colors.grey11,
        },
        active: {
            background: Colors.red3,
            underLine: Colors.orange2,
        },
    },
    defaultButton: {
        default: {
            borderColor: 'transparent',
            backgroundColor: 'default',
            textColor: Colors.blue4,
        },
        hover: {
            borderColor: 'transparent',
            backgroundColor: Colors.blue4,
            textColor: Colors.white,
        },
    },
    pagingButton: {
        clickable: {
            default: {
                background: 'inherit',
                text: Colors.blue6,
                border: Colors.blue6,
            },
            hover: {
                background: Colors.blue4,
                text: Colors.white,
                border: Colors.white,
            },
            active: {
                background: Colors.blue3,
                text: Colors.blue2,
                border: Colors.blue2,
            },
        },
        disabled: {
            background: 'inherit',
            text: Colors.grey8,
            border: Colors.grey8,
        },
        selected: {
            background: Colors.blue2,
            text: Colors.white,
        },
    },
    refreshButton: {
        default: {
            borderColor: 'transparent',
            backgroundColor: 'default',
            textColor: Colors.blue4,
        },
        hover: {
            borderColor: 'transparent',
            backgroundColor: Colors.blue4,
            textColor: Colors.white,
        },
        active: {
            borderColor: Colors.blue5,
            backgroundColor: Colors.blue4,
            textColor: Colors.white,
        },
        disabled: {
            borderColor: 'transparent',
            backgroundColor: 'inherit',
            textColor: Colors.grey5,
        },
    },
    dashboard: {
        noData: {
            text: Colors.grey8,
        },
    },
    chrList: {
        dataArea: {
            background: Colors.grey5,
            boxShadow: 'rgba(0, 0, 0, 0.3)',
        },
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
        },
        link: {
            primary: Colors.blue2,
            hoverBackground: Colors.blue6,
        },
    },
    statusFilter: {
        border: {
            received: Colors.red4,
            assigned: Colors.blue2,
            success: Colors.green1,
            failed: Colors.grey1,
            default: Colors.grey6,
        },
    },
    font: {
        family: "'Open Sans', sans-serif",
        weight: {
            light: 300,
            normal: 400,
            semibold: 600,
            bold: 700,
            extrabold: 800,
        },
    },
    componentPage: {
        dataArea: {
            background: Colors.grey6,
        },
        dataRow: {
            text: Colors.grey5,
            background: Colors.white,
            dropShadow: 'rgba(0, 0, 0, 0.3)',
        },
        link: {
            primary: Colors.blue2,
            hoverBackground: Colors.blue6,
        },
    },
    modalDialog: {
        background: Colors.grey7,
        text: Colors.white,
        headerText: Colors.grey5,
        headerBackground: Colors.white,
        overlay: 'rgba(0, 0, 0, 0.25)',
        divider: Colors.grey4,
        dropShadow: Colors.grey7,
        dataRow: {
            text: Colors.white,
            background: Colors.grey7,
            border: '#000000aa',
        },
        dataArea: {
            background: Colors.grey5,
        },
        tabs: {
            selected: {
                text: Colors.blue2,
                background: Colors.grey6,
            },
            notSelected: {
                text: Colors.grey6,
                background: Colors.grey5,
            },
        },
    },
}
