import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useTheme} from 'styled-components'

import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

import {fetchTrackingIds} from '../../../../../../store/state/tracking-ids/action-creators'
import {
    isLoadingSelector,
    lastUpdatedSelector,
    loadingStateSelector,
} from '../../../../../../store/state/tracking-ids/selectors'
import {ContentContainerBottom} from '../../../../../../templates/fixed-page/content-container-bottom.styled'
import {ContentContainerMiddle} from '../../../../../../templates/fixed-page/content-container-middle.styled'
import LoadingState from '../../../../../../values/loading-state-enum'
import {Header} from '../../../shared/header/header'
import {useTrackingList} from '../../contexts/use-tracking-list'
import {TrackingListLoaded} from './tracking-list-loaded'
import {Footer} from '../footer/footer.styled'
import {Pagination} from '../footer/paging/pagination'
import {RecordSet} from '../footer/record-set/record-set'
import {TableHeader} from './header/tracking-list-table-header'
import {
    ContentContainer,
    ListWrapper,
    TrackingIdTable,
    Wrapper,
} from './_styled/tracking-id-list-table.styled'
import {DataLoading} from '../../../../../../components/data-loading/data-loading'

export function TrackingIdListTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const dispatch = useDispatch()
    const {listExpanded} = useTrackingList()

    const isLoading = useTypedSelector(isLoadingSelector)
    const loadingState = useTypedSelector(loadingStateSelector)
    const lastUpdate = useTypedSelector(lastUpdatedSelector)
    function refresh() {
        dispatch(fetchTrackingIds())
    }
    useEffect(() => {
        if (loadingState === LoadingState.NotPopulated) {
            dispatch(fetchTrackingIds())
        }
    }, [dispatch, loadingState])

    return (
        <Wrapper>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.chrList.dataArea.background}
            >
                <Header
                    text={'Tracking ID List'}
                    refreshFunction={refresh}
                    lastUpdate={lastUpdate}
                />
            </ContentContainerMiddle>

            {listExpanded && (
                <TrackingIdTable>
                    <ContentContainer width={width}>
                        <TableHeader />
                    </ContentContainer>
                    <ContentContainerMiddle
                        width={width}
                        backgroundColor={theme.chrList.dataArea.background}
                    >
                        <ListWrapper>
                            {isLoading ? <DataLoading /> : <TrackingListLoaded />}
                        </ListWrapper>
                    </ContentContainerMiddle>
                    <ContentContainerBottom width={width}>
                        <Footer width={width}>
                            <Pagination />
                            <RecordSet />
                        </Footer>
                    </ContentContainerBottom>
                </TrackingIdTable>
            )}
        </Wrapper>
    )
}
