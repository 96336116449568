import {ReactNode, ReactNodeArray, useReducer} from 'react'
import {TrackingListContext} from './tracking-list-context'
import {trackingListReducer} from './state/reducer'
import {TrackingListState} from './types/tracking-list-state'
import {DEFAULT_TRACKING_LIST_STATE} from './types/default-tracking-list-state'

type TrackingListProviderProps = {
    children: ReactNode | ReactNodeArray
}

export function TrackingListProvider({children}: TrackingListProviderProps): JSX.Element {
    const initialState: TrackingListState = DEFAULT_TRACKING_LIST_STATE

    const [state, dispatch] = useReducer(trackingListReducer, initialState)

    return (
        <TrackingListContext.Provider value={{state, dispatch}}>
            {children}
        </TrackingListContext.Provider>
    )
}
