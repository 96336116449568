import {GridLayout} from './grid-layout.enum'

export function getGridType(width: number): GridLayout {
    if (width >= 1200) {
        return GridLayout.TWO_COLUMNS_WIDE
    } else if (width < 1200 && width > 880) {
        return GridLayout.TWO_COLUMNS_NARROW
    } else if (width <= 880 && width > 600) {
        return GridLayout.ONE_COLUMN_WIDE
    } else {
        return GridLayout.ONE_COLUMN_NARROW
    }
}

export function showTwoColWide(gridLayout: GridLayout): boolean {
    switch (gridLayout) {
        case GridLayout.TWO_COLUMNS_WIDE:
            return true
        case GridLayout.ONE_COLUMN_WIDE:
        case GridLayout.TWO_COLUMNS_NARROW:
        case GridLayout.ONE_COLUMN_NARROW:
            return false
        default:
            return false
    }
}

export function showTwoColNarrow(gridLayout: GridLayout): boolean {
    switch (gridLayout) {
        case GridLayout.TWO_COLUMNS_NARROW:
            return true
        case GridLayout.TWO_COLUMNS_WIDE:
        case GridLayout.ONE_COLUMN_WIDE:
        case GridLayout.ONE_COLUMN_NARROW:
            return false
        default:
            return false
    }
}

export function showOneColWide(gridLayout: GridLayout): boolean {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_WIDE:
            return true
        case GridLayout.TWO_COLUMNS_WIDE:
        case GridLayout.TWO_COLUMNS_NARROW:
        case GridLayout.ONE_COLUMN_NARROW:
            return false
        default:
            return false
    }
}

export function showOneColNarrow(gridLayout: GridLayout): boolean {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_NARROW:
            return true
        case GridLayout.TWO_COLUMNS_WIDE:
        case GridLayout.TWO_COLUMNS_NARROW:
        case GridLayout.ONE_COLUMN_WIDE:
            return false
        default:
            return false
    }
}

export function showOneCol(gridLayout: GridLayout): boolean {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_NARROW:
        case GridLayout.ONE_COLUMN_WIDE:
            return true
        case GridLayout.TWO_COLUMNS_WIDE:
        case GridLayout.TWO_COLUMNS_NARROW:
            return false
        default:
            return false
    }
}

export function showTwoCol(gridLayout: GridLayout): boolean {
    switch (gridLayout) {
        case GridLayout.TWO_COLUMNS_WIDE:
        case GridLayout.TWO_COLUMNS_NARROW:
            return true
        case GridLayout.ONE_COLUMN_NARROW:
        case GridLayout.ONE_COLUMN_WIDE:
            return false
        default:
            return false
    }
}
