import produce from 'immer'
import {DEFAULT_MESSAGE_STATE, DEFAULT_RECEIVER_STATE} from '../types/default-receiver-state'
import {ReceiverState} from '../types/receiver-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'

export const receiverReducer = produce((draft: ReceiverState, action: AllActions) => {
    switch (action.type) {
        case ActionType.READ_DATA:
            draft.message._id = action.payload
            break
        case ActionType.SELECT_PAGE:
            draft.selectedPage = action.payload
            break
        case ActionType.RESET_PAGE:
            draft.selectedPage = DEFAULT_RECEIVER_STATE.selectedPage
            break
        case ActionType.SET_TOTAL_NUMBER_OF_MESSAGES:
            draft.totalNumberOfMessages = action.payload
            break
        case ActionType.CLOSE_DETAILS:
            draft.message._id = DEFAULT_MESSAGE_STATE._id
            break
    }
    return draft
})
