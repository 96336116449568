export interface Environment {
    name: string
    code: string
}

export interface EnvironmentsReduxState {
    isFetching: boolean
    environments: Environment[]
}

export const DEFAULT_ENVIRONMENTS_STATE: EnvironmentsReduxState = {
    isFetching: true,
    environments: [],
}
