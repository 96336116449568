import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './link-area.styled'
import {useEffect, useState} from 'react'
import SwaggerLogo from '../../../../@assets/images/swagger-logo.png'
import {LoadingSpinner} from '../../../../components/loading/loading'

export function LinkArea(): JSX.Element {
    const {width} = useDimensions()
    const [link, setLink] = useState<string | null>(null)

    useEffect(() => {
        async function fetchSwaggerLink() {
            try {
                const result = await fetch(
                    `${process.env.PUBLIC_URL}/config/${
                        process.env.REACT_APP_ALT_BACKEND || 'ic-tools-ui'
                    }.json`,
                )
                const response = await result.json()
                setLink(`${response.baseUrl}/swagger/index.html#`)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        fetchSwaggerLink()
    }, [])

    return (
        <Styled.LinkAreaWrapper>
            {!link ? (
                <LoadingSpinner size={50} />
            ) : (
                <Styled.SwaggerLink
                    id="link-to-swagger_ui-button"
                    rel="noopener,noreferrer"
                    target="_blank"
                    href={link}
                >
                    <Styled.LogoImage width={width} src={SwaggerLogo} />
                </Styled.SwaggerLink>
            )}
        </Styled.LinkAreaWrapper>
    )
}
