import {useContext} from 'react'
import {ReceiverContext} from './paged-receiver-context'
import * as ActionCreators from './state/action-creators'
import {DEFAULT_RECEIVER_STATE} from './types/default-receiver-state'
import {UseReceiverResult} from './use-paged-receiver-result'

export function usePagedReceiver(): UseReceiverResult {
    const {state, dispatch} = useContext(ReceiverContext)

    function readMessage(id: string): void {
        dispatch(ActionCreators.readMessage(id))
    }

    function selectPage(pageIndex: number | undefined): void {
        dispatch(ActionCreators.selectPage(pageIndex))
    }

    function resetPage(): void {
        dispatch(ActionCreators.resetPage())
    }

    function setTotalNumberOfMessages(value: number): void {
        dispatch(ActionCreators.SetTotalNumberOfMessages(value))
    }

    function closeDetails(): void {
        dispatch(ActionCreators.closeDetails())
    }

    return {
        displayDetails: state?.message,
        pageSize: state?.pageSize || DEFAULT_RECEIVER_STATE.pageSize,
        totalNumberOfMessages:
            state?.totalNumberOfMessages || DEFAULT_RECEIVER_STATE.totalNumberOfMessages,
        selectedPage: state?.selectedPage || DEFAULT_RECEIVER_STATE.selectedPage,
        totalNumberOfPages: state?.totalNumberOfPages || DEFAULT_RECEIVER_STATE.totalNumberOfPages,
        readMessage,
        closeDetails,
        selectPage,
        resetPage,
        setTotalNumberOfMessages,
    }
}
