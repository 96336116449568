export type ColorType = string

export const MAX_SCREEN_WIDTH = 1640

export interface CyberOwlTheme {
    colors: {
        text: {
            default: ColorType
            deemphasize: ColorType
            error: ColorType
            warning: ColorType
        }
        background: {
            selectedLocationPage: ColorType
            page: ColorType
            default: ColorType
            lowContrast: ColorType
        }
        boxShadow: {
            highContrast: ColorType
        }
        link: {
            primary: ColorType
        }
        border: {
            lowContrast: ColorType
            active: ColorType
            default: ColorType
        }
        newIndicator: {
            text: ColorType
            flash: ColorType
            background: ColorType
        }
    }
    login: {
        errorBorder: ColorType
        errorBackground: ColorType
        errorContent: ColorType
    }
    loadingPage: {
        background: ColorType
    }
    navigationResponsive: {
        dataArea: {
            background: ColorType
            miniBackground: ColorType
            locationExpandedArea: ColorType
            miniTextColor: ColorType
            textColor: ColorType
        }
        active: {
            background: ColorType
            underLine: ColorType
        }
    }
    defaultButton: {
        default: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        hover: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
    }
    pagingButton: {
        clickable: {
            default: {
                background: ColorType
                text: ColorType
                border: ColorType
            }
            hover: {
                background: ColorType
                text: ColorType
                border: ColorType
            }
            active: {
                background: ColorType
                text: ColorType
                border: ColorType
            }
        }
        selected: {
            background: ColorType
            text: ColorType
        }
        disabled: {
            background: ColorType
            text: ColorType
            border: ColorType
        }
    }
    refreshButton: {
        default: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        hover: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        active: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        disabled: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
    }
    dashboard: {
        noData: {
            text: ColorType
        }
    }
    font: {
        family: string
        weight: {
            light: number
            normal: number
            semibold: number
            bold: number
            extrabold: number
        }
    }
    chrList: {
        dataArea: {
            background: ColorType
            boxShadow: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
        }
        link: {
            primary: ColorType
            hoverBackground: ColorType
        }
    }
    statusFilter: {
        border: {
            received: ColorType
            assigned: ColorType
            success: ColorType
            failed: ColorType
            default: ColorType
        }
    }
    componentPage: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            dropShadow: ColorType
        }
        link: {
            primary: ColorType
            hoverBackground: ColorType
        }
    }
    modalDialog: {
        overlay: ColorType
        text: ColorType
        headerText: ColorType
        headerBackground: ColorType
        background: ColorType
        divider: ColorType
        dropShadow: ColorType

        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            border: ColorType
        }
        tabs: {
            selected: {
                text: ColorType
                background: ColorType
            }
            notSelected: {
                text: ColorType
                background: ColorType
            }
        }
    }
}
