import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'

export interface FormattedOutput {
    result: string
    error: string
    timestamp: string
    output: string
}

export interface QmMessage {
    _id: string
    vesselname: string
    filename: string
    output: string
    formattedOutput: FormattedOutput
}

export const DEFAULT_QM_MESSAGE: QmMessage = {
    _id: '',
    vesselname: '',
    filename: '',
    output: '',
    formattedOutput: {} as FormattedOutput,
}

export interface QmMessagesReduxState {
    loading: LoadingState
    qmMessages: QmMessage[]
    lastUpdate: Moment | null
}

export const DEFAULT_QM_MESSAGES_STATE: QmMessagesReduxState = {
    loading: LoadingState.NotPopulated,
    qmMessages: [],
    lastUpdate: null,
}
