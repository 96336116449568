import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ActionsFooter} from '../../actions/actions-footer'
import * as Styled from './column-based-layout.styled'
import {ColumnBasedLoginLayout, needsReducedHeight} from './column-based-login-layout'
import {AdminSignInButton} from '../../actions/admin-sign-in-button'

export function LoginPanel(): JSX.Element {
    const {height} = useDimensions()

    const reducedHeight = needsReducedHeight(height)

    return (
        <ColumnBasedLoginLayout>
            <Styled.MainActions addBottomMargin={reducedHeight}>
                <Styled.MainActionsGrid reducedGap={reducedHeight}>
                    <AdminSignInButton />
                </Styled.MainActionsGrid>
            </Styled.MainActions>
            <ActionsFooter />
        </ColumnBasedLoginLayout>
    )
}
