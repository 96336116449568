import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'

interface ContentContainerBottomProps {
    width: number | undefined
}

function getMargins(width: number | undefined): string {
    if (!width || width <= MAX_SCREEN_WIDTH) {
        return '0'
    }

    return `0 ${spacing(2)} ${spacing(2)} ${spacing(2)}`
}

export const ContentContainerBottom = styled.div<ContentContainerBottomProps>`
    background-color: ${(props) => props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    margin: ${(props) => props.width && getMargins(props.width)};
    align-self: center;
    z-index: 100;
`
