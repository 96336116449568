import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface HeaderStyleProps {
    width: number
}

export const HeaderStyle = styled.div<HeaderStyleProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > 600 ? 'row' : 'column')};
    justify-content: space-between;
    padding: ${spacing(4)};
    gap: ${spacing(6)};
`
