import styled from 'styled-components'
import {mediumFont, smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'
import {getBorderLeft} from '../../shared/data-helper'

interface FilterCardProps {
    status: string
    activeFilter: boolean
}

export const FilterCard = styled.div<FilterCardProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 150px;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    cursor: pointer;
    border-left: thick solid ${(props) => getBorderLeft(props.status, props.theme)};
    opacity: ${(props) => (props.activeFilter ? 1 : 0.5)};
`

export const FilterCount = styled.a`
    padding-left: ${spacing(1)};
    ${mediumFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const FilterText = styled.h2`
    padding-left: ${spacing(1)};
    ${smallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
