export interface SelectOptionProps {
    label: string
    value: string
}

export const typeSelectOption: SelectOptionProps[] = [
    {label: 'SH(Shell)', value: 'SH'},
    {label: 'PS(PowerShell)', value: 'PS'},
    {label: 'IC(Internal Command)', value: 'IC'},
]

export const assetSelectOption: SelectOptionProps[] = [
    {label: 'Node-Mock-001', value: 'Node-Mock-001'},
    {label: 'Node-Mock-002', value: 'Node-Mock-002'},
    {label: 'Node-Mock-003', value: 'Node-Mock-003'},
]
