import {Messages, ScrollableList} from './response-list.styled'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {filteredMessagesSelector} from '../../../../../../store/state/messages/selectors'
import {ListPopulated} from './list-populated'
import {NoDataCard} from './no-data-card/no-data-card'
import {DataActions} from '../data-actions/data-actions'
import {usePagedReceiver} from '../../contexts/use-paged-receiver'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {ContentContainerBottom} from '../../../../../../templates/fixed-page/content-container-bottom.styled'
import {Footer} from '../footer/footer.styled'
import {Pagination} from '../footer/paging/pagination'
import {RecordSet} from '../footer/record-set/record-set'
import {useEffect} from 'react'

export function ResponseList(): JSX.Element {
    const {width} = useDimensions()
    const filteredMessages = useTypedSelector(filteredMessagesSelector)
    const {selectedPage, setTotalNumberOfMessages} = usePagedReceiver()

    useEffect(() => {
        setTotalNumberOfMessages(filteredMessages.length)
    }, [setTotalNumberOfMessages, filteredMessages])

    const offset = selectedPage ? selectedPage * 10 : 0
    const selectedMessages = filteredMessages.slice(offset, offset + 10)

    return (
        <Messages width={width}>
            <DataActions />
            <ScrollableList width={width}>
                {selectedMessages.length > 0 ? (
                    <ListPopulated messages={selectedMessages} />
                ) : (
                    <NoDataCard text="No messages found" />
                )}
            </ScrollableList>
            <ContentContainerBottom width={width}>
                <Footer width={width}>
                    <Pagination />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </Messages>
    )
}
