import * as Styled from './switch-select.styled'
import {ToggleLeft, ToggleRight} from 'react-feather'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {SelectOptionProps} from '../data-helper'
import {useEffect, useState} from 'react'
import {useSubmitter} from '../../contexts/use-submitter'
import {AssetSelectInput} from '../asset-select-input/asset-select-input'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'

interface SwitchSelectProps {
    label: string
    setChange: (value: string) => void
    selectOption: SelectOptionProps[]
}

export function SwitchSelect({label, setChange}: SwitchSelectProps): JSX.Element {
    const [isAsset, setIsAsset] = useState(true)
    const {customer, setIsIncus, selectedLocationCode} = useSubmitter()
    const nodes = useTypedSelector(nodesSelector)
    const targetIncus = `${customer}-001`

    !isAsset && setChange(targetIncus)
    useEffect(() => {
        setIsIncus(!isAsset)
    }, [isAsset, setIsIncus])

    return (
        <>
            <DataCellFixed label={label}>
                <Styled.SwitchBox>
                    <Styled.SwitchToggle>
                        <Styled.MiniPadding>Incus</Styled.MiniPadding>
                        <Styled.ToggleButton selectable>
                            {isAsset ? (
                                <ToggleRight
                                    onClick={() => {
                                        setIsAsset(false), setChange(targetIncus)
                                    }}
                                    fill="#1f88e5"
                                    color="#f7f7f7"
                                    width={27}
                                    height={27}
                                />
                            ) : (
                                <ToggleLeft
                                    onClick={() => {
                                        setIsAsset(true), setChange('')
                                    }}
                                    fill="#c9c9c9"
                                    color="#f7f7f7"
                                    width={27}
                                    height={27}
                                />
                            )}
                        </Styled.ToggleButton>
                        <Styled.MiniPadding>Asset</Styled.MiniPadding>
                    </Styled.SwitchToggle>
                </Styled.SwitchBox>
            </DataCellFixed>
            <DataCellFixed label={'ASSET'}>
                <AssetSelectInput
                    label="ASSET"
                    setChange={setChange}
                    selectOption={nodes.filter((e) => e.location === selectedLocationCode)}
                    disabled={!isAsset}
                />
            </DataCellFixed>
        </>
    )
}
