import {useToggle} from '../../../contexts/toggle/use-toggle'
import {NavigationLinkStyle} from './_styled/nav-bar-content.styled'

interface NavBarLinkProps {
    id: string
    path: string
    navLinkText: string
}

export function NavBarLink({id, path, navLinkText}: NavBarLinkProps): JSX.Element {
    const {setValue: setMenuOpen} = useToggle()
    function menuClicked() {
        setMenuOpen(false)
    }

    return (
        <NavigationLinkStyle id={id} to={path} activeClassName="activeLink" onClick={menuClicked}>
            {navLinkText}
        </NavigationLinkStyle>
    )
}
