import styled from 'styled-components'

interface ContentWrapperProps {
    z: number
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: ${(props) => props.z};
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
