import SORT_ORDER from '../../../../../../values/sort-order-enum'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function toggleDetailExpanded(id: string): Actions.ToggleDetailExpandedAction {
    return {type: ActionType.TOGGLE_DETAIL_EXPANDED, payload: id}
}

export function toggleListExpanded(value: boolean): Actions.ToggleListExpandedAction {
    return {type: ActionType.TOGGLE_LIST_EXPANDED, payload: value}
}

export function selectPage(pageIndex: number | undefined): Actions.SelectPage {
    return {type: ActionType.SELECT_PAGE, payload: pageIndex}
}

export function SetTotalNumberOfTrackingIds(value: number): Actions.SetTotalNumberOfTrackingIds {
    return {type: ActionType.SET_TOTAL_NUMBER_OF_TRACKING_IDS, payload: value}
}

export function sortData(sortOrder: SORT_ORDER): Actions.SortDataAction {
    return {type: ActionType.SORT_DATA, payload: sortOrder}
}
