import * as Styled from './_styled/tracking-list-table-row.styled'

interface TableCellProps {
    content: string
    gridRow: number
    gridColumn: number
}

export function TableCell({content, gridRow, gridColumn}: TableCellProps): JSX.Element {
    return (
        <Styled.TableDataCell gridRow={gridRow} gridColumn={gridColumn}>
            {content}
        </Styled.TableDataCell>
    )
}
