import {TrackingIdListTable} from './components/table/tracking-id-list-table'
import {TrackingListProvider} from './contexts/tracking-list-provider'

export function TrackingList(): JSX.Element {
    return (
        <TrackingListProvider>
            <TrackingIdListTable />
        </TrackingListProvider>
    )
}
