import styled from 'styled-components'
import {smallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'
import {BREAK_POINT_MINI_LOGO} from '../../data-helpers'

export const TokenAreaWrapper = styled.div``

export const Header = styled.h2`
    color: ${(props) => props.theme.colors.text.default};
    font-size: 14px;
    line-height: 21px;
    padding-left: ${spacing(1)};
`

export const AuthTokenValueArea = styled.div`
    display: flex;
    flex: 1;
`

interface AuthTokenValueProps {
    width: number | undefined
}

export const AuthTokenValue = styled.div<AuthTokenValueProps>`
    max-width: ${(props) =>
        props.width && props.width > BREAK_POINT_MINI_LOGO ? '370px' : '100%'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    color: ${(props) => props.theme.chrList.dataRow.text};
    background-color: ${(props) => props.theme.chrList.dataRow.background};
    padding: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    ${smallFont()};
`

export const CopyButton = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: ${spacing(1)} ${spacing(2)};
    ${smallFont()}
    color: ${(props) => props.theme.chrList.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};

    &:hover {
        background-color: ${(props) => props.theme.chrList.link.hoverBackground};
    }
`

export const IconText = styled.div`
    margin-left: ${spacing(1)};
`
