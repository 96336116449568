import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const DataActionArea = styled.div`
    display: flex;
    gap: ${spacing(1)};
    align-items: flex-end;
    cursor: pointer;
`

export const ButtonWrapper = styled.div`
    display: flex;
    &:hover {
        color: ${(props) => props.theme.colors.border.active};
    }
    :disabled {
        opacity: 0;
        cursor: default;
    }
`
