import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useState} from 'react'
// import {isLoggedInSelector} from '../../../../../../../store/state/session-data/selectors'
import {usePagedReceiver} from '../../../contexts/use-paged-receiver'
import * as Styled from './toggle-details.styled'

interface ToggleDetailsProps {
    onClick: () => void
    isSelected: boolean
}

export function ToggleDetails({onClick, isSelected}: ToggleDetailsProps): JSX.Element {
    const {closeDetails} = usePagedReceiver()
    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <Styled.Button
            onClick={() => {
                isExpanded && isSelected ? closeDetails() : onClick()
                setIsExpanded(!isSelected)
            }}
        >
            <Icon
                glyph={isExpanded && isSelected ? 'DropdownUp' : 'DropdownDown'}
                height={15}
                width={15}
            />
            <Styled.ButtonText>Message Details</Styled.ButtonText>
        </Styled.Button>
    )
}
