import * as Styles from './_styles/nav-bar-links.styled'
import {NavBarLink} from './nav-bar-link'
import RoleChecker from '../../RoleChecker'
import {Role} from '../../../values/Role'

export function NavBarLinks(): JSX.Element {
    return (
        <Styles.NavBarLinks>
            <NavBarLink id="navigation-dashboard" path="/dashboard" navLinkText="Dashboard" />
            {/* <NavBarLink id="navigation-qm-message" path="/qm-message" navLinkText="Quartermaster" /> */}
            <RoleChecker rolesAllowed={['DEVELOPER', 'THREAT_ANALYST'] as Role[]}>
                <NavBarLink id="navigation-system" path="/system" navLinkText="System" />
            </RoleChecker>
        </Styles.NavBarLinks>
    )
}
