import styled from 'styled-components'
import {mediumLargeFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface HeaderStyleProps {
    width: number
}

export const BREAK_POINT_REFRESH_SWITCH = 600
export const BREAK_POINT_SINGLE_COLUMN = 709

export const Header = styled.div<HeaderStyleProps>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'row' : 'column')};
    padding: ${spacing(4)};
    gap: ${spacing(6)};
`
export const PageTitle = styled.div<HeaderStyleProps>`
    ${mediumLargeFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => props.theme.colors.text.default};
`
export const PageTitleWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`

export const UpdatedTimeArea = styled.div<HeaderStyleProps>`
    display: flex;
    padding-top: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 0 : spacing(2))};
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'column' : 'row')};
    justify-content: space-between;
    row-gap: ${spacing(2)};
`

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.link.primary};
    padding: ${spacing(1)};
    margin: 0;
`
export const ButtonText = styled.span`
    ${smallFont()}
    align-items: center;
    padding-left: ${spacing(1)};
`
export const IconWrapper = styled.div`
    width: 20px;
    height: 20px;
`
