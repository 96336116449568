import {Location} from '../../../../../../store/state/locations/state'
import {useSubmitter} from '../../contexts/use-submitter'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {Select} from './select-input.styled'

interface SelectInputProps {
    label: string
    setChange: (value: string) => void
    selectOption: Location[]
}

export function LocationSelectInput({
    label,
    setChange,
    selectOption,
}: SelectInputProps): JSX.Element {
    const {setLocationCode} = useSubmitter()
    // console.log(
    //     selectOption.map(({environment, description, code}) => {
    //         return `[${environment}, ${description}, ${code}]`
    //     }),
    // )
    return (
        <DataCellFixed label={label}>
            <Select
                onChange={(e) => {
                    const selectedIndex = e.target.options.selectedIndex
                    setChange(e.target.value)
                    setLocationCode(`${e.target.options[selectedIndex].getAttribute('data-key')}`)
                }}
            >
                <option key="default-option" value="">
                    Select {label.toLowerCase()}
                </option>

                {selectOption.map(({location, code}) => (
                    <option key={location} data-key={location} value={code}>
                        {code}
                    </option>
                ))}
            </Select>
        </DataCellFixed>
    )
}
