import {Location} from './state'
import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'

const LOCATIONS_URL = '/api/v1/locations'

const requestLocations = (): Actions.RequestLocationsAction => ({
    type: ActionType.REQUEST_LOCATIONS,
})

const setLocations = (locations: Location[]): Actions.ReceiveLocationsAction => ({
    type: ActionType.RECEIVE_LOCATIONS,
    payload: locations,
})

export const fetchLocations = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestLocations())
        REST.get(LOCATIONS_URL).then((response) => {
            dispatch(setLocations(response.data))
        })
    }
}
