import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'

export const Button = styled.button`
    position: relative;
    border: none;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;
`
export const ButtonText = styled.span`
    padding-left: ${spacing(1)};
    font-size: 12px;
`
