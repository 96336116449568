import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

const BREAK_POINT = 500

interface DropdownProps {
    width: number | undefined
}

export const Dropdown = styled.div<DropdownProps>`
    width: 200px;
    max-height: 200px;
    padding: ${spacing(1)};
    border-radius: ${spacing(1)};
    border: 
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    background: ${(props) => props.theme.colors.background.page};
    z-index: 998;
    opacity: 1;
`

export const Ul = styled.ul`
    width: 100%;
    padding-inline-start: 0;
    list-style-type: none;
    max-height: 200px;
    padding: 0;
    margin: 0;
    cursor: default;
    float: left;
`

export const Li = styled.li<DropdownProps>`
    ${mediumSmallFont()};
    padding: ${spacing(1)};
    flex-direction: row;
    width: ${(props) => ((props.width || 0) > BREAK_POINT ? '190px' : '240px')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    border-radius: ${spacing(1)};

    &:hover {
        color: ${(props) => props.theme.defaultButton.hover.textColor};
        background-color: ${(props) => props.theme.defaultButton.hover.backgroundColor};
    }
`
