import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {ReceiverProvider} from './contexts/paged-receiver-provider'
import {LastRow, Layout} from './receiver.styled'
import {Header} from '../shared/header/header'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {useDispatch} from 'react-redux'
import {fetchMessages} from '../../../../store/state/messages/action-creators'
import {
    isLoadingSelector,
    lastUpdatedSelector,
    loadingStateSelector,
} from '../../../../store/state/messages/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {useEffect} from 'react'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {ResponseList} from './components/response-list/response-list'
import {MessageDetails} from './components/message-details/message-details'

export function Receiver(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const dispatch = useDispatch()
    const isLoading = useTypedSelector(isLoadingSelector)
    const loadingState = useTypedSelector(loadingStateSelector)
    const lastUpdate = useTypedSelector(lastUpdatedSelector)

    function refresh() {
        dispatch(fetchMessages())
    }
    useEffect(() => {
        if (loadingState === LoadingState.NotPopulated) {
            dispatch(fetchMessages())
        }
    }, [dispatch, loadingState])

    return (
        <ReceiverProvider>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.chrList.dataArea.background}
            >
                <Header text={'IC Responses'} refreshFunction={refresh} lastUpdate={lastUpdate} />
                <Layout>
                    {isLoading ? (
                        <DataLoading />
                    ) : (
                        <>
                            <ResponseList />
                            {width >= 1200 && <MessageDetails />}
                        </>
                    )}
                </Layout>
            </ContentContainerMiddle>
            <LastRow />
        </ReceiverProvider>
    )
}
