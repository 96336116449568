import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../../../theme/theme'

interface ContentContainerMiddleProps {
    backgroundColor?: ColorType
}

export const ContentContainerMiddle = styled.div<ContentContainerMiddleProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    margin: spacing(2);
    align-self: center;
    z-index: 0;
`

export const ContentArea = styled.div`
    display: flex;
    padding: ${spacing(4)} 0 ${spacing(4)} 0;
    background-color: ${(props) => props.theme.colors.background.lowContrast};
`
