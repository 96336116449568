import * as Styles from './no-data.styled'

interface NoDataCardProps {
    text: string
}
export function NoDataCard({text}: NoDataCardProps): JSX.Element {
    return (
        <Styles.NoDataCardStyle>
            <Styles.NoDataMessage>{text}</Styles.NoDataMessage>
        </Styles.NoDataCardStyle>
    )
}
