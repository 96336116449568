import {SelectOptionProps} from '../data-helper'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {Select} from './type-select-input.styled'
import {useSubmitter} from '../../contexts/use-submitter'
import {Input} from '../data-input/data-input.styled'
import {useEffect} from 'react'

interface SelectInputProps {
    label: string
    setChange: (value: string) => void
    selectOption: SelectOptionProps[]
}

export function TypeSelectInput({label, setChange, selectOption}: SelectInputProps): JSX.Element {
    const {isIncus} = useSubmitter()

    useEffect(() => {
        isIncus ? setChange('SH') : setChange('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIncus])

    return (
        <DataCellFixed label={label}>
            {isIncus ? (
                <Input
                    value={'SH(Shell)'}
                    onChange={(e) => setChange(e.target.value)}
                    disabled={true}
                />
            ) : (
                <Select onChange={(e) => setChange(e.target.value)}>
                    <option key="default-option" value="">
                        Select {label.toLowerCase()}
                    </option>
                    {selectOption.map(({value, label}) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </Select>
            )}
        </DataCellFixed>
    )
}
