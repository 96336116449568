import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {Message} from '../../../../../../store/state/messages/state'
import {usePagedReceiver} from '../../contexts/use-paged-receiver'
import {DataCardNarrow} from '../data-card-narrow/data-card-narrow'
import {DataCardFull} from '../data-card-full/data-card-full'

interface ListPopulatedProps {
    messages: Message[]
}
export function ListPopulated({messages}: ListPopulatedProps): JSX.Element {
    const {readMessage} = usePagedReceiver()
    const {width} = useDimensions()

    return (
        <>
            {messages.map((message) =>
                width > 1200 ? (
                    <DataCardFull
                        key={message._id}
                        message={message}
                        onClick={() => {
                            readMessage(message._id)
                        }}
                    />
                ) : (
                    <DataCardNarrow
                        key={message._id}
                        message={message}
                        onClick={() => {
                            readMessage(message._id)
                        }}
                    />
                ),
            )}
        </>
    )
}
