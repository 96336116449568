import produce from 'immer'
import LoadingState from '../../../values/loading-state-enum'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import ActionType from './action-type'
import {Action} from './actions'
import {DEFAULT_MESSAGES_STATE, MessagesReduxState} from './state'
import moment from 'moment'

export const messageReducer = produce(
    (draft: MessagesReduxState = DEFAULT_MESSAGES_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_MESSAGES:
                draft.loading = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_MESSAGES:
                draft.loading = LoadingState.Loaded
                draft.messages = action.payload
                draft.lastUpdate = moment()
                break
            case ActionType.DELETE_MESSAGE:
                draft.loading = LoadingState.Loaded
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_MESSAGES_STATE
                break
            case ActionType.SET_CUSTOMER:
                draft.receiverFilter.selectedCustomer = action.payload
                break
            case ActionType.SET_TRACKINGID:
                draft.receiverFilter.selectedTrackingId = action.payload
                break
            case ActionType.SET_BY_STATUS:
                draft.receiverFilter.selectedStatus = action.payload
                break
            case ActionType.SORT_DATA:
                draft.receiverFilter.sortOrder = action.payload
                break
            default:
                break
        }
        return draft
    },
)
