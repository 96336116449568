import RoleChecker from '../../components/RoleChecker'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {Role} from '../../values/Role'
import {Receiver} from './components/receiver/receiver'
import {Submitter} from './components/submitter/submitter'
import {TrackingList} from './components/tracking-list/tracking-list'
import {Wrapper} from './dashboard-page.styled'

export function DashboardPage(): JSX.Element {
    const {width} = useDimensions()
    return (
        <ScrollablePageTemplate>
            <ContentContainerTop width={width} />
            <Wrapper>
                <RoleChecker rolesAllowed={['DEVELOPER', 'ENGINEER'] as Role[]}>
                    <Submitter />
                    <TrackingList />
                </RoleChecker>
                <Receiver />
            </Wrapper>
        </ScrollablePageTemplate>
    )
}
