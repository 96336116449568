import produce from 'immer'
import moment from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import ActionType from './action-type'
import {Action} from './actions'
import {DEFAULT_QM_MESSAGES_STATE, FormattedOutput, QmMessagesReduxState} from './state'

export const qmMessagesReducer = produce(
    (
        draft: QmMessagesReduxState = DEFAULT_QM_MESSAGES_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_QM_MESSAGES:
                draft.loading = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_QM_MESSAGES:
                draft.loading = LoadingState.Loaded
                draft.qmMessages = action.payload
                draft.qmMessages.map((qmMessage) => {
                    qmMessage.formattedOutput = {} as FormattedOutput
                    const errorIndex = qmMessage.output.search('error = ')
                    const outputIndex = qmMessage.output.search('output =')
                    const timestampIndex = qmMessage.output.search('time =')
                    if (errorIndex !== -1 && outputIndex !== -1) {
                        qmMessage.formattedOutput.result = qmMessage.output.slice(0, errorIndex - 2)
                        qmMessage.formattedOutput.error = qmMessage.output.slice(
                            errorIndex + 8,
                            outputIndex - 2,
                        )
                    }
                    if (outputIndex !== -1 && timestampIndex !== -1) {
                        qmMessage.formattedOutput.output = qmMessage.output.slice(
                            outputIndex + 9,
                            timestampIndex - 2,
                        )
                    }
                    if (timestampIndex !== -1) {
                        qmMessage.formattedOutput.timestamp = qmMessage.output.slice(
                            timestampIndex + 7,
                        )
                    }
                })
                draft.lastUpdate = moment()
                break
            case ActionType.DELETE_QM_MESSAGES:
                draft.loading = LoadingState.Loaded
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_QM_MESSAGES_STATE
                break
            default:
                break
        }
        return draft
    },
)
