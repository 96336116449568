import {MessageState} from '../../contexts/types/receiver-state'
import * as Styled from './data-card-full.styled'
import {usePagedReceiver} from '../../contexts/use-paged-receiver'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {MessageHandling} from '../message-handling/message-handling'
import RoleChecker from '../../../../../../components/RoleChecker'
import {Role} from '../../../../../../values/Role'
import {formatDate} from '../../../shared/data-helper'

interface MessageCardProps {
    message: MessageState
    onClick: () => void
}

export function DataCardFull({message, onClick}: MessageCardProps): JSX.Element {
    const {displayDetails} = usePagedReceiver()
    const selectedMessageId = displayDetails?._id
    const formattedTime = formatDate(message.timestamp)
    const isSelected = message._id === selectedMessageId
    const {width} = useDimensions()

    return (
        <Styled.MessageCard
            key={message._id}
            status={message.status}
            isSelected={isSelected || false}
            width={width}
        >
            <Styled.MessageArea
                onClick={() => {
                    onClick()
                }}
            >
                <Styled.CardTitleRow>
                    <Styled.CardTitleData id={'message_trackingid'}>
                        {message.trackingid}
                    </Styled.CardTitleData>
                </Styled.CardTitleRow>
                <Styled.CardDataRow width={width}>
                    <Styled.CardDataCell titleOnSameLine={true}>
                        <Styled.ReducedText>Receipt Time:</Styled.ReducedText>
                        <div id={'message_receipttime'}>{formattedTime}</div>
                    </Styled.CardDataCell>
                    <Styled.CardDataCell titleOnSameLine={true}>
                        <Styled.ReducedText>Customer:</Styled.ReducedText>
                        <div id={'message_customer'}>{message.customer}</div>
                    </Styled.CardDataCell>
                </Styled.CardDataRow>
            </Styled.MessageArea>
            <RoleChecker rolesAllowed={['DEVELOPER', 'ENGINEER'] as Role[]}>
                <MessageHandling id={message._id} />
            </RoleChecker>
        </Styled.MessageCard>
    )
}
