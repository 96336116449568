import styled from 'styled-components'
import {mediumFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const Section = styled.section`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
`

export const Header = styled.header`
    color: ${(props) => props.theme.modalDialog.headerText};
    background-color: ${(props) => props.theme.modalDialog.headerBackground};
    width: 100%;
    padding: ${spacing(1)} ${spacing(2)};
    margin: 0;
`
export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`

export const ChrDetailsContent = styled.div`
    padding: ${spacing(4)} ${spacing(4)} ${spacing(2)};
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(2)};
`
export const Actions = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`
