import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {fetchConfig} from '../../store/state/config/action-creators'
import useTypedSelector from '../../hooks/use-typed-selector'
import {fetchingStatusSelector} from '../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../store/state/config/state'
import {errorSelector} from '../../store/state/session-data/selectors'
import {logInStatusSelector} from '../../store/state/session-data/selectors'
import {LoginStatus} from '../../store/state/session-data/state'
import {LoadingDataIndicator} from './components/loading-data-indicator'
import {LoggingOutIndicator} from './components/logging-out-indicator'
import {StartingAppIndicator} from './components/starting-app-indicator'
import {UNKNOWN_USER} from '../../api/api'

import {LoginPanel as ColumnBasedLoginPanel} from './components/formats/column/login-panel'
import {ErrorPanel as ColumnBasedErrorPanel} from './components/formats/column/error-panel'
import {AuthenticationIssue as ColumnBasedAuthenticationIssue} from './components/formats/column/authentication-issue'

import {LoginPanel as RowBasedLoginPanel} from './components/formats/row/login-panel'
import {ErrorPanel as RowBasedErrorPanel} from './components/formats/row/error-panel'
import {AuthenticationIssue as RowBasedAuthenticationIssue} from './components/formats/row/authentication-issue'
import {isRowBasedLoginFormat} from './components/formats/row/row-based-login-layout'

export function LoginPage(): JSX.Element {
    const {width} = useDimensions()
    const configFetchingStatus = useTypedSelector(fetchingStatusSelector)
    const loginStatus = useTypedSelector(logInStatusSelector)
    const error = useTypedSelector(errorSelector)
    const hasError = error !== undefined && error?.length > 0

    const dispatch = useDispatch()
    useEffect(() => {
        if (
            configFetchingStatus === ConfigFetchingStatus.UNINITIALISED ||
            configFetchingStatus === undefined ||
            configFetchingStatus === null
        ) {
            dispatch(fetchConfig())
        }
    }, [configFetchingStatus, dispatch])

    const useRowBasedFormat = isRowBasedLoginFormat(width)

    if (hasError) {
        if (error === UNKNOWN_USER) {
            return useRowBasedFormat ? (
                <RowBasedAuthenticationIssue />
            ) : (
                <ColumnBasedAuthenticationIssue />
            )
        }
        return useRowBasedFormat ? <RowBasedErrorPanel /> : <ColumnBasedErrorPanel />
    }

    if (loginStatus === LoginStatus.LOGGING_OUT) {
        return <LoggingOutIndicator />
    }

    if (configFetchingStatus !== ConfigFetchingStatus.INITIALISED) {
        return <StartingAppIndicator />
    }

    if (loginStatus === LoginStatus.LOGGING_IN) {
        return <LoadingDataIndicator />
    }

    return useRowBasedFormat ? <RowBasedLoginPanel /> : <ColumnBasedLoginPanel />
}
