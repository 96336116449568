import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import SORT_ORDER from '../../../values/sort-order-enum'

export interface Message {
    _id: string
    location: string
    trackingid: string
    timestamp: string
    target: string
    stdout: string
    stderr: string
    status: string
    payload: string
    customer: string
}

export interface ReceiverFilter {
    sortOrder: SORT_ORDER
    selectedCustomer: string
    selectedTrackingId: string
    selectedStatus: string
    // selectedId: string
}

export const DEFAULT_RECEIVER_FILTER: ReceiverFilter = {
    sortOrder: SORT_ORDER.NONE,
    selectedCustomer: '',
    selectedTrackingId: '',
    selectedStatus: '',
    // selectedId: '',
}

export interface MessagesReduxState {
    loading: LoadingState
    messages: Message[]
    lastUpdate: Moment | null
    receiverFilter: ReceiverFilter
}

export const DEFAULT_MESSAGES_STATE: MessagesReduxState = {
    loading: LoadingState.NotPopulated,
    messages: [],
    lastUpdate: null,
    receiverFilter: DEFAULT_RECEIVER_FILTER,
}
