import * as Styled from './search.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {X} from 'react-feather'
import {ChangeEvent} from 'react'
import {useDispatch} from 'react-redux'
import {setTrackingId} from '../../../../../../../store/state/messages/action-creators'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {selectedTrackingIdSelector} from '../../../../../../../store/state/messages/selectors'
import {usePagedReceiver} from '../../../contexts/use-paged-receiver'

export function Search(): JSX.Element {
    const dispatch = useDispatch()
    const selectedTrackingId = useTypedSelector(selectedTrackingIdSelector)
    const isCloseDisabled = selectedTrackingId.length === 0
    const {resetPage} = usePagedReceiver()

    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        dispatch(setTrackingId(e.target.value.trim()))
        resetPage()
    }

    function clickCloseHandler() {
        dispatch(setTrackingId(''))
    }

    return (
        <Styled.Container>
            <Styled.SearchIcon>
                <Icon glyph="Search" height={15} width={15} />
            </Styled.SearchIcon>
            <Styled.SearchInput
                id="tracking-id-search"
                type="text"
                placeholder="Search by Tracking ID"
                value={selectedTrackingId}
                autoComplete="off"
                onChange={onChangeHandler}
            />
            <Styled.CloseIcon disabled={isCloseDisabled} onClick={clickCloseHandler}>
                <X />
            </Styled.CloseIcon>
        </Styled.Container>
    )
}
