import {Node} from './state'
import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'

const LOCATIONS_URL = '/api/v1/nodes'

const requestNodes = (): Actions.RequestNodesAction => ({
    type: ActionType.REQUEST_NODES,
})

const setNodes = (nodes: Node[]): Actions.ReceiveNodesAction => ({
    type: ActionType.RECEIVE_NODES,
    payload: nodes,
})

export const fetchNodes = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestNodes())
        REST.get(LOCATIONS_URL).then((response) => {
            dispatch(setNodes(response.data))
        })
    }
}
