import {useContext} from 'react'
import {UseSubmitterResult} from './use-submitter-result'
import * as ActionCreators from './state/action-creators'
import {SubmitterContext} from './submitter-context'
import {DEFAULT_SUBMIT_DATA, DEFAULT_SUBMIT_RESULT} from './types/default-submitter-state'
import {SubmitResult} from './types/submitter-state'
import {GuidType} from '../../../../../values/generic-type-defintions'

export function useSubmitter(): UseSubmitterResult {
    const {state, dispatch} = useContext(SubmitterContext)

    function setCustomer(value: string): void {
        dispatch(ActionCreators.setCustomer(value))
    }
    function setLocation(value: string): void {
        dispatch(ActionCreators.setLocation(value))
    }
    function setTarget(value: string): void {
        dispatch(ActionCreators.setTarget(value))
    }
    function setCommand(value: string): void {
        dispatch(ActionCreators.setCommand(value))
    }
    function setType(value: string): void {
        dispatch(ActionCreators.setType(value))
    }
    function setCommandFilename(value: string): void {
        dispatch(ActionCreators.setCommandFilename(value))
    }
    function setPayload(value: string): void {
        dispatch(ActionCreators.setPayload(value))
    }
    function setPayloadFilename(value: string): void {
        dispatch(ActionCreators.setPayloadFilename(value))
    }
    function setCompress(value: boolean): void {
        dispatch(ActionCreators.setCompress(value))
    }
    function setLocationCode(value: GuidType): void {
        dispatch(ActionCreators.setLocationCode(value))
    }
    function setDisabled(value: boolean): void {
        dispatch(ActionCreators.setDisabled(value))
    }
    function readCommandPresetList(value: string[]): void {
        dispatch(ActionCreators.readCommandPresetList(value))
    }
    function setIsIncus(value: boolean): void {
        dispatch(ActionCreators.setIsIncus(value))
    }
    function setSubmitResult(value: SubmitResult): void {
        dispatch(ActionCreators.setSubmitResult(value))
    }
    function setSubmitProcessing(value: boolean): void {
        dispatch(ActionCreators.setSubmitProcessing(value))
    }
    function resetChanges(): void {
        dispatch(ActionCreators.resetChanges())
    }

    return {
        submitValue: state ? state.submitData : DEFAULT_SUBMIT_DATA,
        compress: state ? state.submitData.compress : false,
        customer: state ? state.submitData.customer : '',
        location: state ? state.submitData.location : '',
        command: state ? state.submitData.command : '',
        commandFilename: state ? state.submitData.commandfilename : '',
        payloadFilename: state ? state.submitData.payloadfilename : '',
        disabled: state ? state.disabled : true,
        commandPresetList: state ? state.commandPresetList : [],
        selectedLocationCode: state ? state.selectedLocationCode : '',
        isIncus: state ? state.isIncus : false,
        submitResult: state ? state.submitResult : DEFAULT_SUBMIT_RESULT,
        isSubmitProcessing: state ? state.isSubmitProcessing : false,
        setCustomer,
        setLocation,
        setTarget,
        setCommand,
        setType,
        setCommandFilename,
        setPayload,
        setPayloadFilename,
        setCompress,
        setLocationCode,
        setDisabled,
        readCommandPresetList,
        setIsIncus,
        setSubmitResult,
        setSubmitProcessing,
        resetChanges,
    }
}
