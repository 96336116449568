import {MutableRefObject, useRef} from 'react'
import {X} from 'react-feather'
import {useSubmitter} from '../../contexts/use-submitter'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {CloseIcon} from '../search-input/search-input.styled'
import * as Styled from './payload-file-upload.styled'

interface PayloadFileUploadProps {
    label: string
    acceptType?: string
}

export function PayloadFileUpload({label, acceptType}: PayloadFileUploadProps): JSX.Element {
    const placeholder = 'No payload file chosen'
    const {setPayloadFilename, payloadFilename, setPayload, command} = useSubmitter()
    const uploadDisabled = command == ''

    const fileRef = useRef() as MutableRefObject<HTMLInputElement>
    const handleUpload = () => {
        fileRef.current?.click()
    }
    function readFile(event: FileList) {
        const file = event[0]
        const reader = new FileReader()
        setPayloadFilename(file.name)
        reader.onload = (e) => {
            typeof e.target?.result == 'string' && setPayload(`"${e.target?.result}"`)
        }
        reader.readAsText(file)
        // reader.readAsBinaryString(file)
    }

    return (
        <DataCellFixed label={label}>
            <Styled.Wrapper>
                <Styled.Container>
                    <Styled.DisplayFileName uploadDisabled={uploadDisabled || true}>
                        {payloadFilename == '' ? placeholder : payloadFilename}
                    </Styled.DisplayFileName>
                    <CloseIcon
                        disabled={fileRef.current?.value == ''}
                        onClick={() => {
                            fileRef.current.value = ''
                            setPayloadFilename('')
                            setPayload('')
                        }}
                    >
                        <X />
                    </CloseIcon>
                </Styled.Container>
                <Styled.UploadButton onClick={handleUpload} disabled={uploadDisabled}>
                    Choose File
                </Styled.UploadButton>
                <input
                    ref={fileRef}
                    type="file"
                    id="payload_file"
                    name="payload"
                    accept={acceptType}
                    onChange={(e) => e.target.files && readFile(e.target.files)}
                    disabled={uploadDisabled}
                    hidden
                />
            </Styled.Wrapper>
        </DataCellFixed>
    )
}
