import {MouseEvent} from 'react'
import {useSubmitter} from '../../../../../pages/dashboard/components/submitter/contexts/use-submitter'
import * as Styled from './close-with-data-button.styled'

export function CloseWithDataButton(): JSX.Element {
    const {setSubmitProcessing} = useSubmitter()
    function closeChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setSubmitProcessing(false)
    }
    return <Styled.CloseButton onClick={closeChanges}>Close</Styled.CloseButton>
}
