import styled from 'styled-components'
import {MAX_SCREEN_WIDTH} from '../../../../theme/theme'

export const NavigationStyle = styled.nav`
    position: fixed;
    width: 100%;
    height: 3.75rem;
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.background};
    color: ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
    z-index: 999;
    top: 0;
`

export const MainContainerStyle = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
