import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const Container = styled.div`
    display: flex;
    width: 200px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.default};
    border-radius: 3px;
    background: ${(props) => props.theme.colors.background.default};
    margin-left: 3px;
    margin-right: ${spacing(1)};
    padding: ${spacing(1)};
`

interface DisplayFileNameProps {
    uploadDisabled: boolean
}

export const DisplayFileName = styled.div<DisplayFileNameProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    /* ${smallFont()}; */
    height: 30px;
    width: 170px;
    align: center;
    ${(props) => (props.uploadDisabled ? 'opacity: 0.5;' : '')}
`
export const Wrapper = styled.div`
    display: flex;
`

export const UploadButton = styled.button`
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.defaultButton.default.textColor};
    background-color: ${(props) => props.theme.defaultButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    cursor: pointer;
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
    /* &:hover {
        color: ${(props) => props.theme.defaultButton.hover.textColor};
        background-color: ${(props) => props.theme.defaultButton.hover.backgroundColor};
    } */
`
