import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../../../../../theme/theme'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`

interface ContentContainerProps {
    width: number
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.theme.colors.background.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
`

export const ListWrapper = styled.div`
    padding: 0 ${spacing(4)} ${spacing(2)} ${spacing(4)};
    overflow-y: auto;
    overflow-x: none;
    flex: 1;
    z-index: 0;
    height: 500px;
`
export const TrackingIdTable = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
`
