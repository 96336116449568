import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    margin: 0;
    width: 100px;
`

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    word-break: break-word;
`
