import styled from 'styled-components'

export const Value = styled.div`
    font-weight: ${(props) => props.theme.font.weight.normal};
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
`
