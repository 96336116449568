import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const Layout = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${spacing(4)};
    gap: ${spacing(2)};
`

interface HeaderProps {
    width: number
}

export const Header = styled.div<HeaderProps>`
    display: flex;
    ${(props) => (props.width < 1200 ? 'flex-direction: column;' : '')}
    justify-content: space-between;
`

export const LastRow = styled.div``
