export interface SessionDataReduxState {
    loginStatus: LoginStatus
    error: string | undefined
    userLogInFlow: boolean
}

export enum LoginStatus {
    UNKNOWN = 'UNKNOWN',
    LOGGED_OUT = 'LOGGED_OUT',
    LOGGING_IN = 'LOGGING_IN',
    LOGGED_IN = 'LOGGED_IN',
    LOGGING_OUT = 'LOGGING_OUT',
}

export const DEFAULT_SESSION_DATA_STATE: SessionDataReduxState = {
    loginStatus: LoginStatus.UNKNOWN,
    userLogInFlow: false,
    error: undefined,
}
