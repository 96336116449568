import {ChangeEvent} from 'react'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import {sortMessage} from '../../../../../../../store/state/messages/action-creators'
import SORT_ORDER from '../../../../../../../values/sort-order-enum'
import {usePagedReceiver} from '../../../contexts/use-paged-receiver'
import {Select} from './sort-order-drop-down.styled'

export function SortOrderDropDown(): JSX.Element {
    const {width} = useDimensions()
    const ascending = width && width >= 400 ? 'Ascending' : 'asc'
    const descending = width && width >= 400 ? 'Descending' : 'desc'
    const dispatch = useDispatch()
    const {resetPage} = usePagedReceiver()

    const values: [string, string][] = []
    values.push(['-receipttime', `Receipt Time (${descending})`])
    values.push(['+receipttime', `Receipt Time (${ascending})`])

    function onChangeWrapper(e: ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        dispatch(
            sortMessage(
                e.target.value == '+receipttime' ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING,
            ),
        )
        resetPage()
    }

    return (
        <Select onChange={onChangeWrapper}>
            {values.map((item, key) => (
                <option key={key} value={item[0]}>
                    {item[1]}
                </option>
            ))}
        </Select>
    )
}
