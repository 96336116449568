import {GuidType} from '../../../values/generic-type-defintions'

export interface Node {
    alias: string
    hostName: string
    ipAddress: string
    macAddress: string
    node: GuidType
    priorProbability: number
    value: string
    location: GuidType
    environment: string
}

export interface NodesReduxState {
    isFetching: boolean
    nodes: Node[]
}

export const DEFAULT_NODES_STATE: NodesReduxState = {
    isFetching: true,
    nodes: [],
}
