import {FormattedCommand, SubmitterState, SubmitResult} from './submitter-state'

export const DEFAULT_SUBMIT_DATA: FormattedCommand = {
    env: 'prod',
    customer: '',
    location: '',
    target: '',
    command: '',
    filetype: '',
    payload: '',
    compress: false,
    commandfilename: '',
    payloadfilename: '',
}

export const DEFAULT_SUBMIT_RESULT: SubmitResult = {
    status: '',
    message: '',
}

export const DEFAULT_SUBMITTER_STATE: SubmitterState = {
    submitData: DEFAULT_SUBMIT_DATA,
    displayData: DEFAULT_SUBMIT_DATA,
    selectedLocationCode: '',
    disabled: true,
    isIncus: false,
    commandPresetList: [],
    submitResult: DEFAULT_SUBMIT_RESULT,
    isSubmitProcessing: false,
}
