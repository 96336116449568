import AppState from '../../types/app-state'
import {Role} from '../../../values/Role'
import {DEFAULT_USER_STATE, User, UserReduxState} from './state'
import LoadingState from '../../../values/loading-state-enum'

export const currentUserEmailSelector = (state: AppState): string =>
    state.user ? state.user.currentUserEmail : DEFAULT_USER_STATE.currentUserEmail

export const currentUserStateSelector = (state: AppState): UserReduxState =>
    state.user ? state.user : DEFAULT_USER_STATE

export const currentUserSelector = (state: AppState): User =>
    state.user ? state.user.user : DEFAULT_USER_STATE.user

export const rolesSelector = (state: AppState): Role[] =>
    state.user ? state.user.user.role : DEFAULT_USER_STATE.user.role

export const isFetchingRolesSelector = (state: AppState): LoadingState => state.user.isFetching
