import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

export const Container = styled.div`
    /* display: flex;
    justify-content: flex-end;
    height: 30px; */
    display: flex;
    width: 200px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.default};
    border-radius: 3px;
    background: ${(props) => props.theme.colors.background.default};
    margin-left: 3px};
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(props) => props.theme.colors.border.lowContrast};
    /* display: flex;
    align-items: center;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(props) => props.theme.colors.link.primary};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-left: 0;
    border-radius: 0 ${spacing(1)} ${spacing(1)} 0; */
`

export const SearchInput = styled.input`
    ${mediumSmallFont()};
    width: 155px;
    border: 0;
    border-radius: 3px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
        outline: none;
        &::placeholder {
            opacity: 0;
        }
    }
    &::placeholder {
        ${smallFont()};
        color: ${(props) => props.theme.colors.text.deemphasize};
        text-align: center;
    }
    /* font-size: 10px;
    width: 170px;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)} 0 0 ${spacing(1)};
    margin: 0;
    padding: 4px;
    height: 30px; */
`

export const CloseIcon = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    color: ${(props) => props.theme.colors.border.lowContrast};
    background: ${(props) => props.theme.colors.background.default};
    cursor: pointer;
    width: 20px;
    border: 0;
    &:hover {
        color: ${(props) => props.theme.colors.border.active};
    }
    :disabled {
        opacity: 0;
        cursor: default;
    }
`
