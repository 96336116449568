import {isEqual} from 'lodash'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {setByStatus} from '../../../../../../../store/state/messages/action-creators'
import {
    customerFilteredMessagesSelector,
    selectedStatusSelector,
} from '../../../../../../../store/state/messages/selectors'
import StatusState from '../../../../../../../values/status-state-enum'
import {usePagedReceiver} from '../../../contexts/use-paged-receiver'
import * as Styled from './filter-card.styled'

interface FilterCardProps {
    status: StatusState | string
}

export function FilterCard({status}: FilterCardProps): JSX.Element {
    const dispatch = useDispatch()
    const label = status === '' ? 'all' : status
    const {width} = useDimensions()
    const filteredMessages = useTypedSelector(customerFilteredMessagesSelector)
    const selectedStatus = useTypedSelector(selectedStatusSelector)
    const {resetPage} = usePagedReceiver()

    const numberOfFilteredMessages =
        status === ''
            ? filteredMessages.length
            : filteredMessages.filter((message) => message.status === status).length

    function onClickWrapper(): void {
        dispatch(setByStatus(status))
        resetPage()
    }

    return (
        <Styled.FilterCard
            status={status}
            onClick={() => onClickWrapper()}
            activeFilter={isEqual(selectedStatus, status) || selectedStatus == undefined}
        >
            <Styled.FilterCount>{numberOfFilteredMessages}</Styled.FilterCount>
            {width >= 600 && label === 'all' ? (
                <Styled.FilterText>{label.toUpperCase()} MESSAGE(S)</Styled.FilterText>
            ) : (
                <Styled.FilterText>{label.toUpperCase()}</Styled.FilterText>
            )}
        </Styled.FilterCard>
    )
}
