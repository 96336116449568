import {createSelector} from 'reselect'
import {Role} from '../../values/Role'

import {rolesSelector} from '../../store/state/user/selectors'

const rolesCheckerReselector = createSelector(
    // isFetchingRolesSelector,
    rolesSelector,
    (userRoles: Role[]) => {
        return userRoles
    },
)

export default rolesCheckerReselector
