import {CyberOwlTheme} from '../../../../../../theme/theme'
import StatusState from '../../../../../../values/status-state-enum'

export function getBorderLeft(status: string | undefined, theme: CyberOwlTheme): string {
    switch (status) {
        case StatusState.Received:
            return theme.statusFilter.border.received
        case StatusState.Assigned:
            return theme.statusFilter.border.assigned
        case StatusState.Success:
            return theme.statusFilter.border.success
        case StatusState.Failed:
            return theme.statusFilter.border.failed
        default:
            return theme.statusFilter.border.default
    }
}
