import {ConfigReduxState} from '../state/config/state'
import {EnvironmentsReduxState} from '../state/environments/state'
import {LocationsReduxState} from '../state/locations/state'
import {MessagesReduxState} from '../state/messages/state'
import {NodesReduxState} from '../state/nodes/state'
import {QmMessagesReduxState} from '../state/qm-messages/state'
import {SessionDataReduxState} from '../state/session-data/state'
import {TrackingIdsReduxState} from '../state/tracking-ids/state'
import PersistableAppState from './persistable-app-state'

interface AppState extends PersistableAppState {
    sessionData: SessionDataReduxState
    config: ConfigReduxState
    messages: MessagesReduxState
    locations: LocationsReduxState
    nodes: NodesReduxState
    environments: EnvironmentsReduxState
    trackingIds: TrackingIdsReduxState
    qmMessages: QmMessagesReduxState
}

export function toPersistableAppState(state: AppState): PersistableAppState {
    return {
        auth: state.auth,
        user: state.user,
    }
}

export default AppState
