import {ReactNode, MouseEvent, ReactNodeArray} from 'react'
import {ContentWrapper} from './_styled/content-wrapper.styled'
import {Layer} from './_styled/layer.styled'

interface OverlayProps {
    children: ReactNode | ReactNodeArray
    onClickFn: (e?: MouseEvent<HTMLSpanElement>) => void
    z?: number
}

export function Overlay({children, onClickFn, z = 900}: OverlayProps): JSX.Element {
    return (
        <>
            <Layer z={z} onClick={onClickFn} />
            <ContentWrapper z={z + 1}>{children}</ContentWrapper>
        </>
    )
}
