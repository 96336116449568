import {MutableRefObject, useRef} from 'react'
import {X} from 'react-feather'
import {useSubmitter} from '../../contexts/use-submitter'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {CloseIcon} from '../search-input/search-input.styled'
import * as Styled from './command-file-upload.styled'

interface CommandFileUploadProps {
    label: string
    setChange: (value: string) => void
    acceptType?: string
    uploadDisabled?: boolean
}

export function CommandFileUpload({
    label,
    setChange,
    acceptType,
    uploadDisabled,
}: CommandFileUploadProps): JSX.Element {
    const placeholder = 'No command file chosen'
    const {setCommandFilename, commandFilename} = useSubmitter()

    const fileRef = useRef() as MutableRefObject<HTMLInputElement>
    const handleUpload = () => {
        fileRef.current?.click()
    }
    function readFile(event: FileList) {
        const file = event[0]
        const reader = new FileReader()
        setCommandFilename(file.name)
        reader.onload = (e) => {
            typeof e.target?.result == 'string' && setChange(`"${e.target?.result}"`)
        }
        reader.readAsText(file)
        // reader.readAsBinaryString(file)
    }

    return (
        <DataCellFixed label={label}>
            <Styled.Wrapper>
                <Styled.Container>
                    <Styled.DisplayFileName uploadDisabled={uploadDisabled || true}>
                        {commandFilename == '' ? placeholder : commandFilename}
                    </Styled.DisplayFileName>
                    <CloseIcon
                        disabled={fileRef.current?.value == ''}
                        onClick={() => {
                            fileRef.current.value = ''
                            setCommandFilename('')
                            setChange('')
                        }}
                    >
                        <X />
                    </CloseIcon>
                </Styled.Container>
                <Styled.UploadButton onClick={handleUpload} disabled={uploadDisabled}>
                    Choose File
                </Styled.UploadButton>
                <input
                    ref={fileRef}
                    type="file"
                    id="command_file"
                    name="command"
                    accept={acceptType}
                    onChange={(e) => e.target.files && readFile(e.target.files)}
                    disabled={uploadDisabled}
                    hidden
                />
            </Styled.Wrapper>
        </DataCellFixed>
    )
}
