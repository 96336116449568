import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import useTypedSelector from '../hooks/use-typed-selector'
import {DashboardPage} from '../pages/dashboard/dashboard-page'
import {InternalUserPage} from '../pages/internal-user/internal-user'
// import {QmMessages} from '../pages/qm-messages/qm-messages'
import {rolesSelector} from '../store/state/user/selectors'
import {isUserAllowed} from '../values/Role'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRedirectUrl(location: any): string {
    const {from} = location.state || {from: {pathname: '/dashboard'}}
    const url = from.search ? `${from.pathname}/${from.search}` : `${from.pathname}`
    return url
}

export function AuthenticatedRoutes(): JSX.Element {
    const userRoles = useTypedSelector(rolesSelector)
    const isInternalUser = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])

    const pathname = useLocation().pathname
    if (pathname.startsWith('/login')) {
        return <Redirect to={getRedirectUrl(location)} />
    }

    return (
        <Switch>
            <Route path="/dashboard" component={DashboardPage} />
            {/* <Route path="/qm-message" component={QmMessages} /> */}
            {isInternalUser && <Route path="/system" component={InternalUserPage} />}
            <Route path="/" render={() => <Redirect to="/dashboard" />} />
        </Switch>
    )
}
