import {ReactNode, ReactNodeArray, useReducer} from 'react'
import {ReceiverContext} from './paged-receiver-context'
import {receiverReducer} from './state/reducer'
import {ReceiverState} from './types/receiver-state'
import {DEFAULT_RECEIVER_STATE} from './types/default-receiver-state'

type ReceiverProviderProps = {
    children: ReactNode | ReactNodeArray
}

export function ReceiverProvider({children}: ReceiverProviderProps): JSX.Element {
    const initialState: ReceiverState = DEFAULT_RECEIVER_STATE

    const [state, dispatch] = useReducer(receiverReducer, initialState)

    return <ReceiverContext.Provider value={{state, dispatch}}>{children}</ReceiverContext.Provider>
}
