import {useContext} from 'react'
import {TrackingListContext} from './tracking-list-context'
import * as ActionCreators from './state/action-creators'
import {UseTrackingListResult} from './use-tracking-list-result'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {warn} from '../../../../../helpers/logging'
import {DEFAULT_TRACKING_LIST_STATE} from './types/default-tracking-list-state'
import SORT_ORDER from '../../../../../values/sort-order-enum'

export function useTrackingList(): UseTrackingListResult {
    const {state, dispatch} = useContext(TrackingListContext)

    function toggleDetailExpanded(id: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleDetailExpanded(id))
    }

    function toggleListExpanded(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleListExpanded(value))
    }

    function selectPage(pageIndex: number | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.selectPage(pageIndex))
    }

    function setTotalNumberOfTrackingIds(value: number): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.SetTotalNumberOfTrackingIds(value))
    }

    function setSortOrder(sortOrder: SORT_ORDER): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.sortData(sortOrder))
    }

    return {
        detailExpanded: state.detailExpanded,
        listExpanded: state.listExpanded,
        pageSize: state?.pageSize || DEFAULT_TRACKING_LIST_STATE.pageSize,
        totalNumberOfTrackingIds:
            state?.totalNumberOfTrackingIds || DEFAULT_TRACKING_LIST_STATE.totalNumberOfTrackingIds,
        selectedPage: state?.selectedPage || DEFAULT_TRACKING_LIST_STATE.selectedPage,
        totalNumberOfPages:
            state?.totalNumberOfPages || DEFAULT_TRACKING_LIST_STATE.totalNumberOfPages,
        sortOrder: state?.sortOrder || SORT_ORDER.ASCENDING,
        toggleDetailExpanded,
        toggleListExpanded,
        selectPage,
        setTotalNumberOfTrackingIds,
        setSortOrder,
    }
}
