import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    width: 595px;
    min-height: 300px;
    background-color: ${(props) => props.theme.colors.background.default};
    margin: 5px -10px 0;
    padding: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    border-radius: ${spacing(1)};
`

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    ${mediumSmallFont()};
`

export const Title = styled(Label)`
    text-align: center;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    height: 40px;
`

export const Details = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'CUSTOMER CUSTOMER-VALUE' 'TRACKING-ID TRACKING-ID-VALUE' 'LOCATION LOCATION-VALUE' 'TARGET TARGET-VALUE' 'STDOUT STDOUT-VALUE' 'STDERR STDERR-VALUE' 'STATUS STATUS-VALUE' 'PAYLOAD PAYLOAD-VALUE' 'RECEIPT-TIME RECEIPT-TIME-VALUE' 'HANDLING HANDLING-VALUE';
    grid-row-gap: ${spacing(2)};
    grid-column-gap: ${spacing(1)};
    padding: ${spacing(2)};
`
