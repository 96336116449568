import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const LinkAreaWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: ${spacing(1)};
`

export const SwaggerLink = styled.a`
    background: none;

    &:hover {
        background-color: ${(props) => props.theme.chrList.link.hoverBackground};
    }
`

export const LogoImage = styled.img`
    border-radius: ${spacing(1)};
    width: 120px;
    height: 30px;
`
