import {Environment} from './state'
import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'

const ENVIRONMENTS_URL = '/api/v1/environments'

const requestEnvironments = (): Actions.RequestEnvironmentsAction => ({
    type: ActionType.REQUEST_ENVIRONMENTS,
})

const setEnvironments = (environments: Environment[]): Actions.ReceiveEnvironmentsAction => ({
    type: ActionType.RECEIVE_ENVIRONMENTS,
    payload: environments,
})

export const fetchEnvironments = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestEnvironments())
        REST.get(ENVIRONMENTS_URL)
            .then((response) => {
                dispatch(setEnvironments(response.data))
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}
