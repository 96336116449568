import * as Styled from './_styled/hamburger-menu-container.styled'
import {RefObject, useRef} from 'react'
import {useOnClickOutside} from '../../../hooks/use-on-click-outside'
import {HamburgerButton} from './hamburger-button'
import {HamburgerMenu} from './hamburger-menu'
import {useToggle} from '../../../contexts/toggle/use-toggle'

export function HamburgerMenuContainer(): JSX.Element {
    const {value: isOpen, setValue: setToggle} = useToggle()

    const clickOutside = useRef() as RefObject<HTMLDivElement>
    useOnClickOutside(clickOutside, () => setToggle(false))
    return (
        <Styled.LeftContainerStyle ref={clickOutside}>
            <HamburgerButton />
            {isOpen && <HamburgerMenu />}
        </Styled.LeftContainerStyle>
    )
}
