import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const FlexBox = styled.div`
    display: flex;
    margin-bottom: ${spacing(8)};
`

interface TextProps {
    width: number | undefined
}

export const Text = styled.div<TextProps>`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => spacing(props.width !== undefined && props.width > 340 ? 7 : 4)};
`

export const ByText = styled.div`
    margin-top: ${spacing(1)};
`
