import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'
import {TrackingId} from './state'

export const isLoadingSelector = (state: AppState): boolean =>
    state.trackingIds.loading === LoadingState.NotPopulated ||
    state.trackingIds.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState => state.trackingIds?.loading

export const trackingIdsSelector = (state: AppState): TrackingId[] => state.trackingIds?.trackingIds

export const lastUpdatedSelector = (state: AppState): Moment | null | undefined =>
    state.trackingIds.lastUpdate
