import {Value} from './value.styled'
import {TimeStamp} from './timestamp.styled'
import {Label} from './label.styled'
import {Wrapper} from './wrapper.styled'
import moment from 'moment'

interface DataLastUpdatedProps {
    lastUpdate: moment.Moment | null | undefined
}

export function DataLastUpdated({lastUpdate}: DataLastUpdatedProps): JSX.Element {
    return (
        <Wrapper>
            <Label>Data last updated:</Label>
            {lastUpdate ? (
                <Value>
                    {lastUpdate.fromNow()}
                    <TimeStamp>({lastUpdate.format('HH:mm')})</TimeStamp>
                </Value>
            ) : (
                <Value>N/A</Value>
            )}
        </Wrapper>
    )
}
