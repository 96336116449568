import {ButtonStyled} from '../../../../../../components/button/button.styled'
import {ButtonAreaStyle, SubmitAreaStyle} from './submit-area.styled'
import {useSubmitter} from '../../contexts/use-submitter'
import {DEFAULT_SUBMIT_DATA} from '../../contexts/types/default-submitter-state'
import {FormattedCommand} from '../../contexts/types/submitter-state'
import {LoadingModal} from '../../../../../../components/modals/submit-loading/submit-result-modal'
import {REST} from '../../../../../..'

export function SubmitArea(): JSX.Element {
    const {submitValue, resetChanges, setSubmitProcessing, setSubmitResult, isSubmitProcessing} =
        useSubmitter()

    const command: FormattedCommand = {
        env: process.env.ENVIRONMENT || DEFAULT_SUBMIT_DATA.env,
        customer: submitValue?.customer || DEFAULT_SUBMIT_DATA.customer,
        location: submitValue?.location || DEFAULT_SUBMIT_DATA.location,
        target: submitValue?.target || DEFAULT_SUBMIT_DATA.target,
        command: submitValue?.command || DEFAULT_SUBMIT_DATA.command,
        filetype: submitValue?.filetype || DEFAULT_SUBMIT_DATA.filetype,
        commandfilename: submitValue?.commandfilename || DEFAULT_SUBMIT_DATA.commandfilename,
        payload: submitValue?.payload || DEFAULT_SUBMIT_DATA.payload,
        payloadfilename: submitValue?.payloadfilename || DEFAULT_SUBMIT_DATA.payloadfilename,
        compress: submitValue?.compress || DEFAULT_SUBMIT_DATA.compress,
    }

    const submitDisabled =
        command.customer == '' ||
        command.location == '' ||
        command.target == '' ||
        command.command == '' ||
        command.filetype == ''

    const resetDisabled =
        command.customer == '' &&
        command.location == '' &&
        command.target == '' &&
        command.command == '' &&
        command.filetype == ''

    function onClickHandler() {
        setSubmitProcessing(true)
        REST.post('/api/v1/shell', command)
            .then((res) => {
                const result = res.data.toString()
                result.includes('Error')
                    ? setSubmitResult({
                          status: 'failed',
                          message: 'Error occurred. Please try again',
                      })
                    : setSubmitResult({
                          status: 'success',
                          message: result.substr(-37),
                      })
            })
            .catch((err) => {
                setSubmitResult({
                    status: 'failed',
                    message: err.toString(),
                })
                // eslint-disable-next-line no-console
                console.log('error: ', err)
            })
    }
    function refreshComponent() {
        window.location.reload()
    }

    return (
        <SubmitAreaStyle>
            <ButtonAreaStyle>
                <ButtonStyled
                    disabled={resetDisabled}
                    onClick={() => {
                        resetChanges()
                        refreshComponent()
                    }}
                >
                    Reset
                </ButtonStyled>
                <ButtonStyled
                    disabled={submitDisabled}
                    onClick={() => {
                        onClickHandler()
                    }}
                >
                    Submit
                </ButtonStyled>
            </ButtonAreaStyle>
            {isSubmitProcessing && <LoadingModal />}
        </SubmitAreaStyle>
    )
}
