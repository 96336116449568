import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {spacing} from '../../../../theme/spacing'

const breakPoint = 1200
interface NavigationLinkStyleProps {
    width: number | undefined
}

export const NavigationLink = styled(NavLink)<NavigationLinkStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: ${(props) => ((props.width || 0) > breakPoint ? '16px' : '14px')};
    line-height: ${(props) => ((props.width || 0) > breakPoint ? '22px' : '19px')};
    color: ${(props) => props.theme.navigationResponsive.dataArea.textColor};
    width: ${(props) => ((props.width || 0) > breakPoint ? '180px' : '160px')};
    cursor: pointer;
    transition: all 1s ease;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    text-decoration: none;
    :hover {
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        text-decoration: inherit;
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
    &.${(props) => props.activeClassName} {
        border-bottom: 5px solid ${(props) => props.theme.navigationResponsive.active.underLine};
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        button {
            color: ${(props) => props.theme.navigationResponsive.active.underLine};
        }
    }
`

export const IndicatorDot = styled.div`
    display: inline-block;
    width: 15px;
    margin-left: ${spacing(1)};
    height: 15px;
    background-color: ${(props) => props.theme.navigationResponsive.active.background};
    border-radius: 50%;
`
