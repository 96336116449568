import {MessageState, ReceiverState} from './receiver-state'

export const DEFAULT_MESSAGE_STATE: MessageState = {
    _id: '',
    location: '',
    trackingid: '',
    timestamp: '',
    target: '',
    stdout: '',
    stderr: '',
    status: '',
    payload: '',
    customer: '',
}

export const DEFAULT_RECEIVER_STATE: ReceiverState = {
    message: DEFAULT_MESSAGE_STATE,
    isSelected: false,
    totalNumberOfMessages: undefined,
    totalNumberOfPages: undefined,
    pageSize: 10,
    selectedPage: 0,
}
