import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {Header} from '../shared/header/header'

import {InputPanel} from './components/input-panel/input-panel'
import {SubmitArea} from './components/submit-area/submit-area'
import {SubmitterProvider} from './contexts/submitter-provider'
import {HeaderStyle} from './submitter.styled'

export function Submitter(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <SubmitterProvider>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.chrList.dataArea.background}
            >
                <Header text={'Submitter'} />
                <HeaderStyle width={width}>
                    <InputPanel />
                    <SubmitArea />
                </HeaderStyle>
            </ContentContainerMiddle>
        </SubmitterProvider>
    )
}
