import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import {SortableColumn} from './sortable-column'
import * as Styled from './tracking-list-table-header.styled'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.TableHeaderGrid width={width}>
            <Styled.TableHeaderCell gridColumn={2}>Tracking ID</Styled.TableHeaderCell>
            <SortableColumn gridColumn={3} text={'Timestamp'} />
            <Styled.TableHeaderCell gridColumn={4}></Styled.TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
