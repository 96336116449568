import {Message} from './state'
import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'
import SORT_ORDER from '../../../values/sort-order-enum'

export const DATABASE_URL = '/api/v1/response'

export const requestMessages = (): Actions.RequestMessagesAction => ({
    type: ActionType.REQUEST_MESSAGES,
})

const setMessages = (messages: Message[]): Actions.ReceiveMessagesAction => ({
    type: ActionType.RECEIVE_MESSAGES,
    payload: messages,
})

export const fetchMessages = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestMessages())
        REST.get(DATABASE_URL)
            .then((response) => {
                dispatch(setMessages(response.data.data.data))
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}

export const deleteMessage = (id: string): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.delete(`${DATABASE_URL}/${id}`)
            .then(() => {
                dispatch(fetchMessages())
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    }
}

export function setCustomer(customer: string): Actions.SetCustomerAction {
    return {type: ActionType.SET_CUSTOMER, payload: customer}
}

export function setTrackingId(trackingId: string): Actions.SetTrackingIdAction {
    return {type: ActionType.SET_TRACKINGID, payload: trackingId}
}

export function setByStatus(status: string): Actions.SetByStatus {
    return {type: ActionType.SET_BY_STATUS, payload: status}
}

export function sortMessage(sortOrder: SORT_ORDER): Actions.SortDataAction {
    return {type: ActionType.SORT_DATA, payload: sortOrder}
}
