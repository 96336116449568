import produce from 'immer'
import moment from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import ActionType from './action-type'
import {Action} from './actions'
import {DEFAULT_TRACKING_IDS_STATE, TrackingIdsReduxState} from './state'

export const trackingIdReducer = produce(
    (
        draft: TrackingIdsReduxState = DEFAULT_TRACKING_IDS_STATE,
        action: Action | LoggingOutAction,
    ) => {
        switch (action.type) {
            case ActionType.REQUEST_TRACKING_IDS:
                draft.loading = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_TRACKING_IDS:
                draft.loading = LoadingState.Loaded
                draft.trackingIds = action.payload
                draft.lastUpdate = moment()
                break
            case ActionType.DELETE_TRACKING_ID:
                draft.loading = LoadingState.Loaded
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_TRACKING_IDS_STATE
                break
            default:
                break
        }
        return draft
    },
)
