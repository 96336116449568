import {spacing} from '../spacing'

export function getHorizontalPagePadding(width: number | undefined): string {
    if (!width) {
        return spacing(4)
    }

    if (width > 1660) {
        return '0'
    }
    if (width > 1655) {
        return spacing(1)
    }
    if (width > 1650) {
        return spacing(2)
    }
    if (width > 1645) {
        return spacing(3)
    }

    return spacing(4)
}
