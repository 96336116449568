import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const LinksWrapper = styled.ul`
    padding: ${spacing(2)} 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    list-style: none;
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.miniBackground};
    flex-direction: column;
    position: absolute;
    max-height: calc(100vh - 49px);
    overflow: hidden;
    top: 60px;
    right: 0;
    row-gap: ${spacing(1)};
`

export const LogoutLink = styled.div`
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    grid-gap: ${spacing(1)};
    align-items: center;
    cursor: pointer;
    transition: all 1s ease;
    padding: 0 ${spacing(1)} ${spacing(1)};
    border-right: ${spacing(1)} solid transparent;
    &:hover {
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        text-decoration: inherit;
    }
`
