import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import {formatNumber} from '../../../../../../../helpers/formatting'
import {usePagedReceiver} from '../../../contexts/use-paged-receiver'
import * as Styles from './record-set.styled'

export function RecordSet(): JSX.Element | null {
    const {width} = useDimensions()
    const {selectedPage, pageSize, totalNumberOfMessages} = usePagedReceiver()

    if (totalNumberOfMessages == undefined || selectedPage == undefined || pageSize == undefined) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfMessages
            ? totalNumberOfMessages
            : startingIndicator + pageSize - 1
    return (
        <>
            {totalNumberOfMessages !== 0 && (
                <Styles.RecordSet width={width}>
                    Showing{' '}
                    <Styles.Value id="messageset-starting-value">
                        {formatNumber(startingIndicator)}
                    </Styles.Value>{' '}
                    to{' '}
                    <Styles.Value id="messageset-end-value">
                        {formatNumber(endIndicator)}
                    </Styles.Value>{' '}
                    of{' '}
                    <Styles.Value id="messageset-total-value">
                        {formatNumber(totalNumberOfMessages)}
                    </Styles.Value>
                </Styles.RecordSet>
            )}
        </>
    )
}
