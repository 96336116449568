import {MessageState} from '../../contexts/types/receiver-state'
import * as Styled from './data-card-narrow.styled'
import {usePagedReceiver} from '../../contexts/use-paged-receiver'
import {MessageDetailsExpanded} from '../message-details-expanded/message-details-expanded'
import {ToggleDetails} from './toggle-details/toggle-details'
import {MessageHandling} from '../message-handling/message-handling'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {formatDate} from '../../../shared/data-helper'

interface DataCardNarrowProps {
    message: MessageState
    onClick: () => void
}

export function DataCardNarrow({message, onClick}: DataCardNarrowProps): JSX.Element {
    const {displayDetails} = usePagedReceiver()
    const selectedMessageId = displayDetails?._id
    const formattedTime = formatDate(message.timestamp)
    const isSelected = message._id === selectedMessageId
    const {width} = useDimensions()

    return (
        <Styled.MessageCard
            key={message._id}
            status={message.status}
            isSelected={isSelected || false}
        >
            <Styled.MessageArea
                onClick={() => {
                    onClick()
                }}
            >
                <Styled.CardTitleRow>
                    <Styled.CardTitleData id={'message_trackingid'}>
                        {message.trackingid}
                    </Styled.CardTitleData>
                    <MessageHandling id={message._id} />
                </Styled.CardTitleRow>
                <Styled.CardDataRow width={width}>
                    <Styled.CardDataCell titleOnSameLine={true}>
                        <Styled.ReducedText>Receipt Time:</Styled.ReducedText>
                        <div id={'message_receipttime'}>{formattedTime}</div>
                    </Styled.CardDataCell>
                    <Styled.CardDataCell titleOnSameLine={true}>
                        <Styled.ReducedText>Customer:</Styled.ReducedText>
                        <div id={'message_customer'}>{message.customer}</div>
                    </Styled.CardDataCell>
                </Styled.CardDataRow>
            </Styled.MessageArea>
            <ToggleDetails onClick={onClick} isSelected={isSelected} />
            {isSelected && <MessageDetailsExpanded />}
        </Styled.MessageCard>
    )
}
