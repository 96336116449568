import * as Styled from './_styled/hamburger-nav-bar.styled'
import {LogoContainer} from './logo-container'
import {HamburgerMenuContainer} from './hamburger-menu-container'
import {ToggleProvider} from '../../../contexts/toggle/toggle-provider'

export function HamburgerNavBar(): JSX.Element {
    return (
        <Styled.NavigationStyle id="hamburger-navigation">
            <Styled.MainContainerStyle>
                <LogoContainer />
                <ToggleProvider>
                    <HamburgerMenuContainer />
                </ToggleProvider>
            </Styled.MainContainerStyle>
        </Styled.NavigationStyle>
    )
}
