import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {Aside} from '../../aside/aside'
import {Header, HeaderSize} from '../../header/header'
import * as Styled from './column-based-layout.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/session-data/selectors'
import {ReactNode, ReactNodeArray} from 'react'
import {LoginPageTemplate} from '../../../page-template/login-page-template'

function getMarginRight(width: number | undefined): Styled.DetailRightMargin {
    return width !== undefined && width > 1290
        ? Styled.DetailRightMargin.WIDE
        : width !== undefined && width > 920
        ? Styled.DetailRightMargin.NORMAL
        : Styled.DetailRightMargin.NARROW
}

export function needsReducedHeight(height: number | undefined): boolean {
    return height !== undefined && height < 330
}

export function needsReducedWidth(width: number | undefined): boolean {
    return width !== undefined && width < 760
}

interface ColumnBasedLoginLayoutProps {
    children: ReactNode | ReactNodeArray
}

export function ColumnBasedLoginLayout({children}: ColumnBasedLoginLayoutProps): JSX.Element {
    const {width, height} = useDimensions()
    const error = useTypedSelector(errorSelector)
    const hasError = error !== undefined && error?.length > 0

    const reducedHeight = needsReducedHeight(height)
    const reducedWidth = needsReducedWidth(width)
    return (
        <LoginPageTemplate>
            <Styled.Content>
                <Styled.Detail rightMargin={getMarginRight(width)}>
                    <Header headerSize={HeaderSize.LARGE} />
                    <Styled.AsideWrapper width={width}>
                        <Aside />
                    </Styled.AsideWrapper>
                </Styled.Detail>
                <Styled.CallToActionSection
                    reducedHeight={reducedHeight}
                    reducedWidth={reducedWidth}
                    showError={hasError}
                >
                    {children}
                </Styled.CallToActionSection>
            </Styled.Content>
        </LoginPageTemplate>
    )
}
