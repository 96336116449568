import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {BREAK_POINT_SINGLE_COLUMN} from '../../../shared/header/header.styled'
import {useTrackingList} from '../../contexts/use-tracking-list'
import * as Styled from './_styled/tracking-list-table-detail.styled'
import {TableDataCell} from './_styled/tracking-list-table-row.styled'

interface TableCellProps {
    gridRow: number
    gridColumn: number
    id: string
}

export function TableDetailCell({gridRow, gridColumn, id}: TableCellProps): JSX.Element {
    const {detailExpanded, toggleDetailExpanded} = useTrackingList()
    const isExpanded = detailExpanded.get(id) === true
    const {width} = useDimensions()
    const text = width > BREAK_POINT_SINGLE_COLUMN ? 'View Submitted Details' : 'View Details'

    function onClickToggle() {
        toggleDetailExpanded(id)
    }

    return (
        <TableDataCell gridRow={gridRow} gridColumn={gridColumn} left={true}>
            <Styled.Button onClick={onClickToggle} id={`expand-notes-button_${id}`}>
                <Styled.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styled.IconWrapper>
                <Styled.ButtonText>{text}</Styled.ButtonText>
            </Styled.Button>
        </TableDataCell>
    )
}
