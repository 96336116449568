import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {ReactNode} from 'react'
import SORT_ORDER from '../../../../../../../values/sort-order-enum'
import {useTrackingList} from '../../../contexts/use-tracking-list'
import {IconStyled, TableHeaderContent} from './header-column.styled'
import {TableHeaderCell} from './tracking-list-table-header.styled'

interface SortableColumnProps {
    gridColumn: number
    text: string
    children?: ReactNode
}

export function SortableColumn({gridColumn, text, children}: SortableColumnProps): JSX.Element {
    const {sortOrder, setSortOrder} = useTrackingList()
    return (
        <TableHeaderCell gridColumn={gridColumn}>
            <TableHeaderContent
                onClick={() =>
                    sortOrder === SORT_ORDER.ASCENDING
                        ? setSortOrder(SORT_ORDER.DESCENDING)
                        : setSortOrder(SORT_ORDER.ASCENDING)
                }
                active={true}
            >
                {text}
                <IconStyled arrowDec={sortOrder === SORT_ORDER.DESCENDING}>
                    <Icon width={12} height={17} glyph="Arrow" />
                </IconStyled>
            </TableHeaderContent>
            {children}
        </TableHeaderCell>
    )
}
