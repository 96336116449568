import {Search} from './search/search'
import {SelectFilter} from './select-filter/select-filter'
import * as Styled from './data-action.styled'
import {SortOrderDropDown} from './sort-order/sort-order-drop-down'
import {FilterByStatus} from './filter-by-status/filter-by-status'

export function DataActions(): JSX.Element {
    return (
        <Styled.HeaderWrapper>
            <Styled.FirstRow>
                <FilterByStatus />
            </Styled.FirstRow>
            <Styled.SecondRow>
                <SortOrderDropDown />
                <SelectFilter option={'Customer'} />
                <Search />
            </Styled.SecondRow>
        </Styled.HeaderWrapper>
    )
}
