import {useSubmitter} from '../../contexts/use-submitter'
import {SearchInput} from '../search-input/search-input'
import {InputPanelStyle, PanelLayout} from './input-panel.styled'
import {LocationSelectInput} from '../location-select-input/select-input'
import {CommandFileUpload} from '../command-file-upload/command-file-uplaod'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {getGridType} from '../../../shared/grid-layout-helper'
import {assetSelectOption, typeSelectOption} from '../data-helper'
import {SwitchSelect} from '../switch-select/switch-select'
import {ToggleProvider} from '../../../../../../contexts/toggle/toggle-provider'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../../store/state/locations/selectors'
import {environmentsSelector} from '../../../../../../store/state/environments/selectors'
import {TypeSelectInput} from '../type-select-input/type-select-input'
import {useEffect} from 'react'
import {sortedEnvironmentsSelector} from '../../../shared/sorted-environments-selector'
import {CheckboxSelect} from '../checkbox-select/checkbox-select'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {PayloadFileUpload} from '../payload-file-upload/payload-file-uplaod'

export function InputPanel(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridType(width)

    const {
        submitValue,
        setCustomer,
        setLocation,
        setTarget,
        setCommand,
        setType,
        readCommandPresetList,
        customer,
        commandPresetList,
    } = useSubmitter()

    const locations = useTypedSelector(locationsSelector)
    const environments = useTypedSelector(environmentsSelector)
    const customers = useTypedSelector(sortedEnvironmentsSelector)

    const acceptType = submitValue?.filetype == 'SH' ? '.sh' : '.ps1'
    const uploadDisabled = submitValue?.filetype == '' ? true : false
    const currentCustomerName =
        environments.filter((environment) => environment.code === customer).length != 0
            ? environments.filter((environment) => environment.code === customer)[0].name
            : ''

    useEffect(() => {
        async function readConfig() {
            try {
                const result = await fetch(
                    `${process.env.PUBLIC_URL}/config/${
                        process.env.REACT_APP_ALT_BACKEND || 'ic-tools-ui'
                    }.json`,
                )
                const response = await result.json()
                readCommandPresetList(response.commandPresetList)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        readConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PanelLayout>
            <InputPanelStyle gridLayout={gridLayout}>
                <ToggleProvider>
                    <SearchInput label="CUSTOMER" values={customers} setChange={setCustomer} />
                    <LocationSelectInput
                        label="LOCATION"
                        setChange={setLocation}
                        selectOption={locations.filter((e) =>
                            customer === '' ? e.environment : e.environment === currentCustomerName,
                        )}
                    />
                    <SwitchSelect
                        label="TARGET"
                        setChange={setTarget}
                        selectOption={assetSelectOption}
                    />
                    {submitValue?.filetype === 'IC' ? (
                        <SearchInput
                            label="COMMAND"
                            values={commandPresetList}
                            setChange={setCommand}
                        />
                    ) : (
                        <CommandFileUpload
                            label="COMMAND"
                            setChange={setCommand}
                            acceptType={acceptType}
                            uploadDisabled={uploadDisabled}
                        />
                    )}
                    <TypeSelectInput
                        label="TYPE"
                        setChange={setType}
                        selectOption={typeSelectOption}
                    />
                    {submitValue?.filetype !== 'IC' && <PayloadFileUpload label="PAYLOAD" />}
                    {submitValue?.payload !== '' && (
                        <DataCellFixed label={'COMPRESSED'}>
                            <CheckboxSelect />
                        </DataCellFixed>
                    )}
                </ToggleProvider>
            </InputPanelStyle>
        </PanelLayout>
    )
}
