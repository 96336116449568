import {TrackingId} from '../../../../../../store/state/tracking-ids/state'
import {DataCellFixed} from './data-cell-fixed-detail-expanded'
import * as Styled from './_styled/tracking-id-detail-expanded.styled'

interface TrackingIdDetailExpandedProps {
    gridRow: number
    detailExpanded: boolean
    trackingid: TrackingId
}
export function TrackingIdDetailExpanded({
    gridRow,
    detailExpanded,
    trackingid,
}: TrackingIdDetailExpandedProps): JSX.Element {
    return (
        <Styled.TableDetailRow gridRow={gridRow}>
            {detailExpanded && (
                <Styled.DetailSection>
                    <DataCellFixed label="Customer">
                        {trackingid.submittedargs.customer}
                    </DataCellFixed>
                    <DataCellFixed label="Location">
                        {trackingid.submittedargs.location}
                    </DataCellFixed>
                    <DataCellFixed label="Target">{trackingid.submittedargs.target}</DataCellFixed>
                </Styled.DetailSection>
            )}
        </Styled.TableDetailRow>
    )
}
