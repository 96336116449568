import produce from 'immer'
import ActionType from './action-type'
import {Action} from './actions'
import {DEFAULT_NODES_STATE, NodesReduxState} from './state'

export const nodesReducer = produce(
    (draft: NodesReduxState = DEFAULT_NODES_STATE, action: Action) => {
        switch (action.type) {
            case ActionType.REQUEST_NODES:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_NODES:
                draft.isFetching = false
                draft.nodes = action.payload
                break
            default:
                break
        }
        return draft
    },
)
