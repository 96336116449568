import * as Styled from './_styled/tracking-list-table-populated.styled.'
import {TrackingId} from '../../../../../../store/state/tracking-ids/state'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {TableRow} from './tracking-list-table-row'
import {useTrackingList} from '../../contexts/use-tracking-list'
import {useEffect} from 'react'
import SORT_ORDER from '../../../../../../values/sort-order-enum'

interface TrackingListTablePopulatedProps {
    trackingids: TrackingId[]
}

export function TrackingListTablePopulated({
    trackingids,
}: TrackingListTablePopulatedProps): JSX.Element {
    const {width} = useDimensions()
    const {setTotalNumberOfTrackingIds, selectedPage, sortOrder} = useTrackingList()

    useEffect(() => {
        setTotalNumberOfTrackingIds(trackingids.length)
    }, [setTotalNumberOfTrackingIds, trackingids])

    const offset = selectedPage ? selectedPage * 10 : 0
    const selectedTrackingIds =
        sortOrder === SORT_ORDER.ASCENDING
            ? trackingids
                  .slice(0)
                  .reverse()
                  .slice(offset, offset + 10)
            : trackingids.slice(0).slice(offset, offset + 10)

    return (
        <Styled.TableDataGrid width={width}>
            {selectedTrackingIds.map((trackingid, index) => (
                <TableRow key={trackingid._id} trackingid={trackingid} index={index} />
            ))}
        </Styled.TableDataGrid>
    )
}
