import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import StatusState from '../../../../../../values/status-state-enum'
import {getBorderLeft} from '../shared/data-helper'

interface MessageCardProps {
    status: StatusState | string
    isSelected?: boolean
    width: number
}

export const MessageCard = styled.div<MessageCardProps>`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing(2)};
    width: ${(props) => (props.width > 1350 ? '725px' : '600px')};
    ${smallFont()}
    padding: ${(props) => (props.isSelected ? '5px 10px 5px' : spacing(2))};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    border-radius: 0 ${spacing(1)} ${spacing(1)} 0;
    border-left: thick solid ${(props) => getBorderLeft(props.status, props.theme)};

    ${(props) =>
        props.isSelected
            ? `border-bottom: 3px solid #cccccc; border-top: 3px solid #cccccc; border-right: 3px solid #cccccc;`
            : ''}
`

export const MessageArea = styled.div`
    cursor: pointer;
`

export const CardTitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing(2)};
`
export const CardTitleData = styled.h2`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    flex: 1;
`

interface CardDataRowProps {
    width: number
}

export const CardDataRow = styled.div<CardDataRowProps>`
    display: flex;
    ${(props) => (props.width <= 1350 ? 'flex-direction: column;' : '')}
`
interface CardDataCellProps {
    titleOnSameLine?: boolean
}

export const CardDataCell = styled.div<CardDataCellProps>`
    flex: 1;
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    padding-right: ${spacing(1)};
    width: 250px;
`

export const ReducedText = styled.div`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    padding-right: ${spacing(2)};
`
