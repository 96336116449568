import {ChangeEvent} from 'react'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {setCustomer} from '../../../../../../../store/state/messages/action-creators'
import {sortedEnvironmentsSelector} from '../../../../shared/sorted-environments-selector'
import {usePagedReceiver} from '../../../contexts/use-paged-receiver'
import {Select} from './select-filter.styled'

interface SelectFilterProps {
    option: string
}

export function SelectFilter({option}: SelectFilterProps): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const environments = useTypedSelector(sortedEnvironmentsSelector)
    const {resetPage} = usePagedReceiver()

    function onChangeWrapper(e: ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        dispatch(setCustomer(e.target.value))
        resetPage()
    }

    return (
        <Select onChange={onChangeWrapper} id="select-filter" width={width}>
            <option key="default-option" value="">
                All {option}
            </option>
            {environments?.map((value) => (
                <option key={value} value={value}>
                    {value.toUpperCase()}
                </option>
            ))}
        </Select>
    )
}
