import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import checkboxSVG from '../.././@assets/icons/checkmark-grey-1.svg'

export const Label = styled.label`
    position: relative;
    display: flex;
    width: 200px;
    align-items: center;
    padding: ${spacing(1)};
    margin-top: ${spacing(1)};
`

export const Text = styled.span`
    vertical-align: middle;
`

export const StyledCheckbox = styled.span`
    width: 19px;
    height: 19px;
    border-radius: 3px;
    background: white;
    border: 1px solid #ccc;
`

export const CheckboxInput = styled.input`
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: ${spacing(2)};
    height: ${spacing(2)};

    &:hover ~ ${StyledCheckbox} {
        border-color: #1f88e5;
    }
    &:checked + ${StyledCheckbox} {
        border: none;
        background-color: #1f88e5;
        content: url('${checkboxSVG}');
    }
`
