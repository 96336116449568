import * as Styled from './header.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DataLastUpdated} from '../last-updated/data-last-updated'
import {RefreshButton} from '../refresh-button/refresh-button'
import {Moment} from 'moment'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useTrackingList} from '../../tracking-list/contexts/use-tracking-list'

interface HeaderProp {
    text: string
    refreshFunction?: () => void
    lastUpdate?: Moment | null
}
export function Header({text, refreshFunction, lastUpdate}: HeaderProp): JSX.Element {
    const {width} = useDimensions()
    const {toggleListExpanded, listExpanded} = useTrackingList()
    function onClickToggle() {
        toggleListExpanded(!listExpanded)
    }
    const isTrackingIdList = text == 'Tracking ID List'
    const showRefresh = isTrackingIdList ? listExpanded : true

    return (
        <Styled.Header width={width}>
            <Styled.PageTitleWrapper>
                <Styled.PageTitle width={width}>{text}</Styled.PageTitle>
                {isTrackingIdList && (
                    <Styled.Button onClick={onClickToggle}>
                        <Styled.IconWrapper>
                            <Icon
                                glyph={listExpanded ? 'DropdownUp' : 'DropdownDown'}
                                height={17}
                                width={17}
                            />
                        </Styled.IconWrapper>
                        <Styled.ButtonText>
                            {listExpanded ? 'Close List' : 'Show List'}
                        </Styled.ButtonText>
                    </Styled.Button>
                )}
            </Styled.PageTitleWrapper>
            {showRefresh && refreshFunction && (
                <Styled.UpdatedTimeArea width={width}>
                    <RefreshButton refreshFunction={refreshFunction} />
                    {lastUpdate && <DataLastUpdated lastUpdate={lastUpdate} />}
                </Styled.UpdatedTimeArea>
            )}
        </Styled.Header>
    )
}
