import {useSubmitter} from '../../../../pages/dashboard/components/submitter/contexts/use-submitter'
import * as Styled from './show-result-form.styled'
import {SubmitProgress} from './submit-progress/submit-progress'

export function ShowResult(): JSX.Element {
    const {submitResult, customer} = useSubmitter()
    return (
        <Styled.Section onClick={(e) => e.stopPropagation()}>
            <Styled.Header>{`Submit Command to ${customer}`}</Styled.Header>
            <Styled.Actions>
                <SubmitProgress submitResult={submitResult} />
            </Styled.Actions>
        </Styled.Section>
    )
}
