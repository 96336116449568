import {CheckCircle, XCircle} from 'react-feather'
import {SubmitResult} from '../../../../../pages/dashboard/components/submitter/contexts/types/submitter-state'
import {LoadingSpinner} from '../../../../loading/loading'
// import {CancelButton} from '../cancel-button/cancel-button'
import {CloseButton} from '../close-button/close-button'
import {CloseWithDataButton} from '../close-with-data-button/close-with-data-button'
import {ShowTrackigId} from '../show-tracking-id/show-tracking-id'
import * as Styled from './submit-progress.styled'

interface SubmitProgressProps {
    submitResult: SubmitResult
}

export function SubmitProgress({submitResult}: SubmitProgressProps): JSX.Element {
    switch (submitResult.status) {
        case 'success':
            return (
                <>
                    <CheckCircle color="#7ac943" size={40} />
                    <ShowTrackigId receivedMessage={submitResult.message} />
                    <CloseButton />
                </>
            )
        case 'failed':
            return (
                <>
                    <XCircle color="#ff002b" size={40} />
                    <Styled.Text>{submitResult.message}</Styled.Text>
                    <CloseWithDataButton />
                </>
            )
        case '':
            return (
                <>
                    <LoadingSpinner size={40} />
                    <Styled.Text>Submitting Command...</Styled.Text>
                    {/* <CancelButton disabled={true} /> */}
                </>
            )
    }
}
